<template>
  <div class="zw-newsconsul">
    <!--大图-->
    <div class="zw-newsconsul-bgImg" :style="{backgroundImage:'url('+ pictureBaseUrl + picUrl +')'}">
      <div class="zw-newsconsul-bgImg-content">
        <p>新闻资讯</p>
        <span>获悉最新前沿资讯 洞察数字转型之路</span>
      </div>
    </div>
    <!--导航栏 -->
    <div class="zw-newsconsul-context">
      <el-tabs v-model="activeName" @tab-click="tabClick">
        <el-tab-pane label="企业动态" name="1">
          <ul class="zw-dynamic-list">
            <li v-for="(item, index) in newsconsulList" :key="index" class="zw-dynamic-item">
              <div>
                <img :src="pictureBaseUrl + item.fileId" style="width: 300px;height: 130px" alt="">
              </div>
              <div class="zw-newsconsul-item-right">
                <p style="cursor: pointer" @click="selectDetail(item)">{{ item.title }}</p>
                <div style="padding: 5px 0">
                  <span v-html="item.context" />
                  <span style="color: #C7000B;cursor: pointer" @click="selectDetail(item)">【查看详情】</span>
                </div>
                <div style="display: flex;">
                  <span style="width: 40%">{{ item.timeData }}</span>
                  <span>浏览量：{{ item.browse }}</span>
                </div>
              </div>
            </li>
          </ul>
          <div style="display: flex;justify-content: flex-end;margin: 50px 0 20px 0">
            <el-pagination
              v-show="dataTotal>0"
              background
              :current-page.sync="currentPageThird"
              :page-size="queryData.limit"
              layout="prev, pager, next, jumper"
              :total="dataTotal"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="媒体报道" name="2">
          <ul class="zw-dynamic-list">
            <li v-for="(item, index) in newsconsulList" :key="index" class="zw-dynamic-item">
              <div>
                <img :src="pictureBaseUrl + item.imgUrl" style="width: 300px;height: 130px" alt="">
              </div>
              <div class="zw-newsconsul-item-right">
                <p>{{ item.title }}</p>
                <div style="padding: 5px 0">
                  <span v-html="item.context" />
                  <span style="color: #C7000B;cursor: pointer" @click="selectDetail(item)">【查看详情】</span>
                </div>
                <div style="display: flex;">
                  <span style="width: 40%">{{ item.timeData }}</span>
                  <span>浏览量：{{ item.browse }}</span>
                </div>
              </div>
            </li>
          </ul>
          <div style="display: flex;justify-content: flex-end;margin: 50px 0 20px 0">
            <el-pagination
              v-show="dataTotal>0"
              background
              :current-page.sync="currentPageThird"
              :page-size="queryData.limit"
              layout="prev, pager, next, jumper"
              :total="dataTotal"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="产品白皮书" name="3">
          <ul class="zw-whitePaper-list">
            <li v-for="(item, index) in whitePaperList" :key="index" class="zw-whitePaper-item">
              <div style="text-align: center">
                <img :src="pictureBaseUrl + item.imgUrl" style="width: 263px;height: 265px" alt="">
              </div>
              <div class="zw-newsconsul-item-bottom">
                <p>{{ item.title }}</p>
                <p style="display: none">{{ item.textContent }}</p>
                <div style="display: flex;align-items: center" @click="downFile(item.textContent, item.fileName)">
                  <i style="margin: 0 5px;font-size: 16px" class="vxe-icon--download" />
                  <span>下载</span>
                </div>
              </div>
            </li>
          </ul>
          <div style="display: flex;justify-content: flex-end;margin: 50px 0 20px 0">
            <el-pagination
              v-show="dataTotal>0"
              background
              :current-page.sync="currentPageThird"
              :page-size="queryData.limit"
              layout="prev, pager, next, jumper"
              :total="dataTotal"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane label="资料库" name="4">
          <ul class="zw-whitePaper-list">
            <li v-for="(item, index) in whitePaperList" :key="index" class="zw-whitePaper-item">
              <div style="text-align: center">
                <img :src="pictureBaseUrl + item.imgUrl" style="width: 263px;height: 265px" alt="">
              </div>
              <div class="zw-newsconsul-item-bottom">
                <p>{{ item.title }}</p>
                <div style="display: flex;align-items: center" @click="downFile(item.textContent, item.fileName)">
                  <i style="margin: 0 5px;font-size: 16px" class="vxe-icon--download" />
                  <span>下载</span>
                </div>
              </div>
            </li>
          </ul>
          <div style="display: flex;justify-content: flex-end;margin: 50px 0 20px 0">
            <el-pagination
              v-show="dataTotal>0"
              background
              :current-page.sync="queryData.page"
              :page-size="queryData.limit"
              layout="prev, pager, next, jumper"
              :total="dataTotal"
              @current-change="getDataList()"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getNews } from '@/js/newsConsul'
import { downLoadFile } from '@/js/upload'
import { getShowAdvert } from '@/js/carousel'
import { pictureBaseUrl } from '@/utils/request'
export default {
  name: 'NewsConsul',
  data() {
    return {
      picUrl: '',
      queryData: {
        limit: 10,
        page: 1,
        title: '',
        type: '1'
      },
      dataTotal: 0,
      activeName: '1',
      currentPageFirst: 1,
      currentPageThird: 1,
      whitePaperList: [],
      newsconsulList: [],
      pictureBaseUrl: pictureBaseUrl
    }
  },
  created() {
    this.getShowAdvert()
    this.getDataList()
  },
  methods: {
    selectDetail(item) {
      this.$router.push({
        name: '/newsDetail',
        params: item
      })
    },
    getShowAdvert() {
      getShowAdvert({ type: '5' }).then(res => {
        if (res && res.code === 200) {
          this.picUrl = res.data[0].url
        }
      })
    },
    getDataList() {
      getNews(this.queryData).then(res => {
        if (res && res.code === 200) {
          if (res.data.records.length > 0) {
            if (res.data.records[0].type === '1' || res.data.records[0].type === '2') {
              this.newsconsulList = []
              this.dataTotal = res.data.total
              for (const record of res.data.records) {
                let context = ''
                if (record.pureText !== null && record.pureText !== '') {
                  if (record.pureText.length > 85) {
                    context = record.pureText.substr(0, 85) + '...'
                  } else {
                    context = record.pureText
                  }
                }
                this.newsconsulList.push({
                  imgUrl: record.fileId,
                  title: record.title,
                  timeData: this.dateFormat(record.createTime),
                  context: context.trimStart(),
                  textContent: record.textContent,
                  browse: record.viewCount,
                  contentType: record.contentType,
                  updateTime: record.updateTime,
                  viewCount: record.viewCount,
                  id: record.id,
                  fileId: record.fileId
                })
              }
            } else {
              this.whitePaperList = []
              this.dataTotal = res.data.total
              for (const record of res.data.records) {
                this.whitePaperList.push({
                  imgUrl: record.fileId,
                  title: record.title.split('.')[0],
                  fileName: record.fileName,
                  textContent: record.textContent
                })
              }
            }
          }
        }
      })
    },
    dateFormat(date) {
      var d = new Date(date)
      var d2 = d.getFullYear() + '年' + (d.getMonth() + 1) + '月' + d.getDate() + '日'
      return d2
    },
    tabClick(tab, event) {
      this.queryData.type = this.activeName
      this.getDataList()
    },
    downFile(fileId, title) {
      downLoadFile(fileId).then(res => {
        const blob = new Blob([res])
        const elink = document.createElement('a')
        elink.download = title
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      })
    }
  }
}
</script>

<style scoped>

</style>
