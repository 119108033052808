<template>
  <div class="zw-product-four-template">
    <!--    背景图-->
    <div
      :style="{backgroundImage:'url('+ bgImgUrl +')'}"
      class="zw-four-bg"
    >
      <div class="zw-four-bg-tile">
        <div>电子运维系统</div>
        <span>故障分析，运维工单流程化管理</span>
      </div>
    </div>
    <div class="zw-four-bg-introduc">
      <div
        style="display: flex;justify-content: center;align-items: center;
        margin-bottom: 20px"
      >
        <img src="../../assets/image/product/fourLeft.png" alt="">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
         padding: 0 20px"
        >系统简介</div>
        <img src="../../assets/image/product/fourRight.png" alt="">
      </div>
      <div
        style="display: flex;padding: 50px 0 0;width: 100%;
      justify-content: center;flex-direction: column"
      >
        <div
          style="font-size: 16px;color: #000000;
        line-height: 26px"
        >
          电子运维系统主要负责中国电信陕西分公司网络层设备的障碍工单、风险操作、主动性作业计划、重要通信保
          障、生产指挥任务、群障主动告知等场景流程化管理工作，是电信宽带业务、移动业务重要支撑保障系统。
        </div>
        <el-divider />
      </div>
    </div>
    <div class="zw-four-bg-trait">
      <div
        style="display: flex;justify-content: center;align-items: center;
        margin-bottom: 20px"
      >
        <img src="../../assets/image/product/fourLeft.png" alt="">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
          padding: 0 20px"
        >系统特点</div>
        <img src="../../assets/image/product/fourRight.png" alt="">
      </div>
      <div class="zw-trait-context">
        <ul>
          <li v-for="(item, index) in traitList" :key="index">
            <el-card style="width: 100%" shadow="always">
              <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
                <div style="text-align: center;padding-right: 20px;">
                  {{ item.text }}
                </div>
                <img :src="item.imgUrl" width="100px" height="100px" alt="">
              </div>
            </el-card>
          </li>
        </ul>
      </div>
    </div>
    <!--免费获取方案-->
    <div
      :style="{backgroundImage:'url('+ fontImg +')'}"
      class="zw-four-footer"
    >
      <div
        style="display: flex;align-items: center;height: 100%;
      justify-content: center;flex-direction: column"
      >
        <div style="font-size: 28px;font-weight: bold;color: #000000;padding-bottom: 30px">
          即刻为你的企业定制解决方案
        </div>
        <el-button size="small" type="primary" @click="handleProgramme">免费获取方案</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Electron',
  data() {
    return {
      bgImgUrl: require('../../assets/image/product/fourBg.jpg'),
      fontImg: require('../../assets/image/product/fourfoot.jpg'),
      traitList: [
        {
          imgUrl: require('../../assets/image/product/four01.png'),
          text: '按专业、地市分类管理，满足综合化维护场景管理；'
        },
        {
          imgUrl: require('../../assets/image/product/four02.png'),
          text: '工单闭环化管理，流转过程AI自动提醒；'
        },
        {
          imgUrl: require('../../assets/image/product/four03.png'),
          text: '提供故障分析、巡检分析、隐患分析等可视化智能工作台；'
        },
        {
          imgUrl: require('../../assets/image/product/four04.png'),
          text: '提供手机APP全场景服务，方便维护人员移动办公。'
        }
      ]
    }
  },
  methods: {
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
