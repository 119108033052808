<template>
  <div class="zw-getScheme">
    <div v-if="formShow" class="zw-getScheme-content">
      <div class="zw-getScheme-left">
        <span class="zw-getScheme-left-title">提交申请，你将会获得</span>
        <ul class="zw-getScheme-left-list">
          <li v-for="(item,index) in getSchemeList" :key="index">
            <span />
            <p>{{ item.title }}</p>
          </li>
        </ul>
      </div>
      <div class="zw-getScheme-right">
        <div class="zw-getScheme-right-title">
          <p>提交申请，即可开启数字化转型</p>
          <span>请留下你的联系方式，我们会尽快联系您！</span>
        </div>
        <vxe-form
          ref="xForm"
          class="my-form"
          title-align="left"
          title-width="80"
          :data="formData"
          :rules="rules"
        >
          <vxe-form-item title="手机号" prop="phone" field="phone" span="24" :item-render="{}">
            <template v-slot="scope">
              <vxe-input v-model="formData.phone" type="integer" :controls="false" placeholder="请输入手机号" maxlength="11" />
            </template>
          </vxe-form-item>
          <!--          <vxe-form-item title="验证码" prop="verificaCode" field="verificaCode" span="24" :item-render="{}">-->
          <!--            <template v-slot="scope">-->
          <!--              <div style="display: flex;align-items: center">-->
          <!--                <vxe-input-->
          <!--                  v-model="formData.verificaCode"-->
          <!--                  placeholder="短信验证码"-->
          <!--                  @input="handleVerifica"-->
          <!--                />-->
          <!--                <div style="margin-left: 30px">-->
          <!--                  <el-button class="depth" type="danger" plain size="mini">-->
          <!--                    获取验证码-->
          <!--                  </el-button>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </vxe-form-item>-->
          <vxe-form-item prop="name" title="姓名" field="name" span="24" :item-render="{}">
            <template v-slot="scope">
              <vxe-input v-model="formData.name" placeholder="请输入姓名" />
            </template>
          </vxe-form-item>
          <vxe-form-item prop="corporateName" title="公司名称" field="corporateName" span="24" :item-render="{}">
            <template v-slot="scope">
              <vxe-input
                v-model="formData.corporateName"
                placeholder="请输入公司名称"
              />
            </template>
          </vxe-form-item>
          <vxe-form-item prop="product" title="产品" field="corporateName" span="24" :item-render="{}">
            <template v-slot="scope">
              <vxe-select v-model="formData.product" multiple placeholder="">
                <vxe-option v-for="item in productList" :key="item.key" :value="item.key" :label="item.name" />
              </vxe-select>
            </template>
          </vxe-form-item>
          <vxe-form-item prop="programme" title="方案需求" field="programme" span="24" :item-render="{}">
            <template v-slot="scope">
              <vxe-textarea
                v-model="formData.programme"
                style="height: 100px"
                placeholder="请输入公司名称"
                maxlength="200"
                show-word-count
              />
            </template>
          </vxe-form-item>
          <vxe-form-item align="center" span="24">
            <template v-slot>
              <el-button class="zw-getScheme-btn" style="width: 100%;height: 45px" :loading="loadingFlag" @click="handleGetSchemeBtn">
                <span>立即获取</span>
              </el-button>
            </template>
          </vxe-form-item>
        </vxe-form>
      </div>
    </div>
    <div v-if="!formShow" class="zw-getScheme-success">
      <i class="el-icon-success" style="font-size: 78px;color: #C7000B" />
      <p>
        客户顾问将会在3个工作日内与您联系，请您保持手机畅通，注意接听。
      </p>
      <div style="padding: 20px 0">
        <el-button class="depth" type="danger" plain size="mini">
          返回首页 （{{ count }}s后自动跳转）
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { addPlan } from '@/js/getScheme'
import { getProductMenu } from '@/js/product'
export default {
  name: 'GetScheme',
  data() {
    return {
      loadingFlag: false,
      formShow: true,
      count: 3,
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator({ itemValue }) {
              // 手机号一般最小以“13”开头
              const regMobile = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
              if (!regMobile.test(itemValue)) {
                return new Error('请输入合法的手机号!')
              }
            },
            trigger: 'blur'
          }],
        verificaCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        corporateName: [{ required: true, message: '请输入公司名称', trigger: 'blur' }]
      },
      formData: {
        phone: '',
        verificaCode: '',
        name: '',
        corporateName: '',
        programme: '',
        product: ''
      },
      productList: [
        { key: '预算管理', name: '预算管理' },
        { key: '电子运维', name: '电子运维' }
      ],
      verificaShow: false,
      getSchemeList: [
        {
          title: '免费上门或线上产品演示'
        },
        {
          title: '专业客户顾问全程服务'
        },
        {
          title: '企业定制化解决方案'
        },
        {
          title: '全天候业务咨询服务'
        }

      ]
    }
  },
  created() {
    this.getProductMenu()
  },
  methods: {
    getProductMenu() {
      this.productList = []
      getProductMenu().then(res => {
        if (res && res.code === 200) {
          for (const datum of res.data) {
            this.productList.push({
              key: datum.menuName,
              name: datum.menuName
            })
          }
        }
      })
    },
    // 验证码改变
    handleVerifica() {
      this.verificaShow = this.formData.verificaCode !== ''
    },
    handleGetSchemeBtn() {
      this.$refs.xForm.validate((errMap) => {
        if (errMap) {
          this.$message.warning('校验不通过！')
        } else {
          this.loadingFlag = true
          const parm = {
            phone: this.formData.phone,
            name: this.formData.name,
            companyName: this.formData.corporateName,
            planDemand: this.formData.programme,
            product: this.formData.product.join(',')
          }
          addPlan(parm).then(res => {
            if (res && res.code === 200) {
              this.$message({
                type: 'success',
                message: res.msg
              })
              this.formShow = false
              this.count = 3
              var timeout = setInterval(() => {
                if (this.count === 0) {
                  clearInterval(timeout)
                  this.$router.push({
                    path: '/homePage',
                    query: {
                      active: '1'
                    }
                  })
                } else {
                  this.count--
                }
              }, 1000)
              this.loadingFlag = false
            }
          })
        }
      })
    }

  }
}
</script>

<style scoped>

</style>
