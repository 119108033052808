<template>
  <div class="zw-product-five-template">
    <!--    背景图-->
    <div
      :style="{backgroundImage:'url('+ bgImgUrl +')'}"
      class="zw-five-bg"
    >
      <div class="zw-five-bg-tile">
        <div>内控系统</div>
        <span>提供财政云一体化前置管理服务</span>
      </div>
    </div>
    <div class="zw-five-bg-introduc">
      <div
        style="display: flex;justify-content: center;
      flex-direction: column;align-items: center;margin-bottom: 20px"
      >
        <img src="../../assets/image/product/xtjs.png" alt="">
        <div
          style="font-size: 28px;margin-top: -20px;
        font-weight: bold;color: #000000;"
        >系统简介</div>
        <span style="border: 3px solid #00c7c5;width: 80px;margin-top: 20px" />
      </div>
      <div style="display: flex;padding: 50px 0;width: 100%;justify-content: center">
        <div style="">
          <img src="../../assets/image/product/xtwj.png" width="450" alt="">
        </div>
        <div
          style="display: flex;flex-direction: column;
        justify-content: space-between;width: 40%;min-width: 450px; "
        >
          <div
            style="font-size: 16px;color: #000000;
        line-height: 26px"
          >
            预算单位前置处理系统的服务于各预算单位，集自动办公表单流程、行政管理功能与预算管理一体化系统对接为一身的信息化综合
            政务系统。系统实现了与陕西省财政云一体化系统间的数据对接；满足各单位及内部各部门对于财政资金的管理（项目储备、预算
            编制、预算批复、预算执行、项目进度）和资金使用效率的需求。系统主要包括办公协作、财务管理、项目管理、资产管理、文件
            管理、党建事务管理及定制业务等功能。
          </div>
          <div style="display: flex;justify-content: right;width: 100%">
            <el-divider />
            <div style="border: 3px solid #00c7c5;width: 80px;margin-top: -2px" />
          </div>
        </div>
      </div>
    </div>
    <div class="zw-five-bg-trait">
      <div
        style="display: flex;justify-content: center;
      flex-direction: column;align-items: center;margin-bottom: 20px"
      >
        <img src="../../assets/image/product/xtjs.png" alt="">
        <div
          style="font-size: 28px;margin-top: -20px;
        font-weight: bold;color: #000000;"
        >系统特点</div>
        <span style="border: 3px solid #00c7c5;width: 80px;margin-top: 20px" />
      </div>
      <div class="zw-trait-context">
        <ul>
          <li v-for="(item, index) in traitList" :key="index">
            <div>
              <img :src="item.imgUrl" alt="">
            </div>
            <div style="text-align: center;color: #000000">
              {{ item.text }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--免费获取方案-->
    <div
      :style="{backgroundImage:'url('+ fontImg +')'}"
      class="zw-five-footer"
    >
      <div
        style="display: flex;align-items: center;height: 100%;
      justify-content: center;flex-direction: column"
      >
        <div style="font-size: 28px;font-weight: bold;color: #000000;padding-bottom: 30px">
          即刻为你的企业定制解决方案
        </div>
        <el-button size="small" type="primary" @click="handleProgramme">免费获取方案</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Preprocess',
  data() {
    return {
      bgImgUrl: require('../../assets/image/product/fiveTemBg.jpg'),
      fontImg: require('../../assets/image/product/mfhqfa.jpg'),
      traitList: [
        {
          imgUrl: require('../../assets/image/product/five01.png'),
          text: '通盘掌握资金使用情况，便于领导层监控和全盘统筹；'
        },
        {
          imgUrl: require('../../assets/image/product/five02.png'),
          text: '过程留痕、审批有依据，责任可追溯、避免审计风险；'
        },
        {
          imgUrl: require('../../assets/image/product/five03.png'),
          text: '管理制度化、制度表单化、表单流程化、流程数据化；'
        },
        {
          imgUrl: require('../../assets/image/product/five04.png'),
          text: '报表统计便捷、及时、准确，为管理层提供决策依据；'
        },
        {
          imgUrl: require('../../assets/image/product/five05.png'),
          text: '标准内置嵌套，界面操作简单清晰，提高办公效率；'
        },
        {
          imgUrl: require('../../assets/image/product/five05.png'),
          text: '挖掘数据可用价值，提升单位整体数字化管理水平'
        }
      ]
    }
  },
  methods: {
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
