<template>
  <div>
    <el-carousel
      :interval="cutTime"
      arrow="always"
      :autoplay="autoplay"
      trigger="click"
      :indicator-position="autoplay?'':'none'"
      height="600px"
    >
      <!--      <el-carousel-item>-->
      <!--        &lt;!&ndash;视频播放器 &ndash;&gt;-->
      <!--        <div @mouseover="isIn = true" @mouseout="isIn = false">-->
      <!--          <video-->
      <!--            ref="videoPlayer"-->
      <!--            :controls="isIn"-->
      <!--            style="object-fit: cover"-->
      <!--          >-->
      <!--            <source src="../assets/mp4/sxxzw.mp4" type="video/mp4">-->
      <!--            您的浏览器不支持Video标签。-->
      <!--          </video>-->
      <!--          <i v-show="!isPlay" class="play-btn el-icon-video-play" @click="playVideo" />-->
      <!--          <i v-show="isPlay && isIn" class="play-btn el-icon-video-pause" @click="pauseVideo" />-->
      <!--        </div>-->
      <!--      </el-carousel-item>-->
      <el-carousel-item v-for="(item,index) in carouselList" :key="index">
        <div :style="{backgroundImage:'url('+ pictureBaseUrl + item.url +')'}" style="width: 100%;height: 600px;background-size: 100% 100%">
          <div v-if="item.show===3" style="display: flex;align-items: center;height: 100%;padding-left: 150px">
            <ul>
              <li style="padding: 5px 0;font-size: 18px;">
                服务热线： 029-89181990
                <el-button
                  size="mini"
                  type="danger"
                  style="background-color: #C7000B;border-color: #C7000B;"
                >全国布局</el-button>
              </li>
              <li
                style="font-weight: bold;font-size: 40px;color: #000000;
                display: flex;"
              >
                <div>
                  <span style="padding-right: 20px">科技创新</span>
                  <span>引领未来</span>
                </div>
                <div class="videPlay" style="margin-left: 20px;cursor: pointer" @click="handleVideoPlay">
                  <i class="el-icon-video-play" />
                </div>
              </li>
              <li style="padding: 5px 0;font-size: 20px">
                助力客户实现数字化转型升级
              </li>
              <li style="padding-top: 60px">
                <el-button @click="konwMore">了解更多</el-button>
              </li>
            </ul>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <!--    视频播放-->
    <vxe-modal
      v-model="model"
      class="zw-viodeo"
      title="视频播放"
      width="950px"
    >
      <template v-slot>
        <div style="background-color: #000000;position: relative">
          <video
            id="maskmore"
            autoplay
            controls
            loop
            muted
            controlslist="nodownload"
            preload
            style="width: 100%;height: 100%;"
            src="../assets/mp4/sxxzw.mp4"
          >
            <!--            controlslist="nodownload"-->
            <!--            <source src="../assets/mp4/sxxzw.mp4" type="video/mp4">-->
            <!--            您的浏览器不支持Video标签。-->
          </video>
        </div>
        <span
          style="position: absolute;font-size: 24px;
            top: 5px;right: 10px;color: #FFFFFF"
          @click="handleVideoPlayClose"
        >
          <i class="el-icon-close" />
        </span>
      </template>
    </vxe-modal>
  </div>
</template>

<script>
import { getShowAdvert } from '@/js/carousel'
import { pictureBaseUrl } from '@/utils/request'

export default {
  // components: {videoPlayer},
  name: 'Carousel',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    type: {
      type: String
    }
  },
  data() {
    return {
      model: false,
      videoUrl: require('../assets/mp4/sxxzw.mp4'),
      carouselList: [],
      isPlay: false, // 播放状态
      isIn: false, // 鼠标是否位于播放器内
      cutTime: 2000, // 轮播时间，单位毫秒
      autoplay: true, // 是否自动切换
      pictureBaseUrl: pictureBaseUrl
    }
  },
  created() {
    this.getShowAdvert()
  },
  mounted() {
    // this.listenVideo()
  },
  /**
   * 里面的方法只有被调用才会执行
   */
  methods: {
    handleVideoPlayClose() {
      document.getElementById('maskmore').pause()
      this.videoUrl = null
      this.model = false
    },
    handleVideoPlay() {
      var maskmorePaly = document.getElementById('maskmore')
      if (maskmorePaly !== null) {
        if (maskmorePaly.paused) {
          maskmorePaly.play()
        }
      }
      this.model = true
    },
    konwMore() {
      this.$router.push({
        path: '/aboutUs',
        query: {
          active: '6-1'
        }
      })
    },
    // 监听视频的播放、暂停、播放完毕等事件
    // listenVideo() {
    //   // 注意：这样获取的dom是一个数组
    //   const myVideo = this.$refs.videoPlayer
    //   // 监听播放
    //   myVideo.addEventListener('play', () => {
    //     this.autoplay = false
    //     this.isPlay = true
    //   })
    //   // 监听暂停
    //   myVideo.addEventListener('pause', () => {
    //     this.autoplay = true
    //     this.isPlay = false
    //   })
    //   // 监听播放完毕
    //   myVideo.addEventListener('ended', () => {
    //     // 时间归零
    //     myVideo.currentTime = 0
    //     this.autoplay = true
    //     this.isPlay = false
    //   })
    // },
    // 播放视频
    playVideo() {
      // 注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
      const myVideo = this.$refs.videoPlayer
      myVideo.play()
    },
    // 暂停视频
    pauseVideo() {
      // 注意：这样获取的dom是一个数组，必须加上[0]索引才能正确的添加事件
      const myVideo = this.$refs.videoPlayer
      myVideo.pause()
    },
    getShowAdvert() {
      getShowAdvert({ type: this.type }).then(res => {
        if (res && res.code === 200) {
          this.carouselList = res.data
          console.log(this.carouselList)
        }
      })
    }
  }
}
</script>

<style>
.show-imgs-container {
  height: 100%;
}

.el-carousel {
  width: 100%;
  height: 100%;
}

.el-carousel__container {
  width: 100%;
  height: 100%;
}

.el-carousel__item {
  width: 100%;
  height: 100%;
  /*border-radius: 10px;*/
}
.videPlay :hover{
  color: #626772;
}
.zw-viodeo .vxe-modal--box{
  border: none !important;
}
.zw-viodeo .vxe-modal--header{
  display: none !important;
}
.zw-viodeo .vxe-modal--body{
  padding: 0 !important;
}
/*video {*/
/*  width:100%;*/
/*  height:100%;*/
/*  position: fixed;*/
/*  display: inline-block;*/
/*  vertical-align: baseline;*/
/*  object-fit: fill;*/
/*}*/
/*.play-btn {*/
/*  font-size: 60px;*/
/*  color: #fff;*/
/*  position: absolute;*/
/*  top: 50%;*/
/*  left: 50%;*/
/*  transform: translate(-50%, -50%);*/
/*}*/
/*.play-btn:hover{*/
/*   cursor: pointer;*/
/* }*/

</style>
