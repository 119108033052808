<template>
  <div class="zw-case">
    <!--大图-->
    <div class="zw-case-bgImg" :style="{backgroundImage:'url('+ pictureBaseUrl + picUrl +')'}">
      <div class="zw-case-bgImg-content">
        <p>精选客户案例</p>
        <span>分享数字化转型与升级过程中的成功经验</span>
        <!--        <div class="zw-case-buttom">-->
        <!--          精彩案例集下载-->
        <!--        </div>-->
      </div>
    </div>
    <!--内容-->
    <div class="zw-case-centext">
      <ul class="zw-context-list">
        <li v-for="(item, index) in ceasCenterList" :key="index" style="cursor: pointer" class="zw-context-item" @click="selectDetail(item)">
          <div :style="{backgroundImage:'url('+ pictureBaseUrl + item.imgUrl +')'}" class="zw-item-img">
            <span>{{ item.name }}</span>
          </div>
          <div style="margin: 0 20px">
            <p class="zw-item-title">{{ item.title }}</p>
            <span style="padding: 10px 0;color: #9999A6;display: inline-block">{{ item.remarks }}</span>
            <ul class="zw-item-tabs">
              <li v-for="(itemTab, indexTab) in item.tabs" :key="indexTab">
                {{ itemTab }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <div style="display: flex;justify-content: flex-end;margin: 50px 0 20px 0">
        <el-pagination
          background
          :current-page.sync="queryData.page"
          :page-size="queryData.limit"
          layout="prev, pager, next, jumper"
          :total="dataTotal"
          @current-change="getDataList()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getCaseView, getDictByDicKey } from '@/js/caseCenter'
import { getShowAdvert } from '@/js/carousel'
import { pictureBaseUrl } from '@/utils/request'

export default {
  name: 'CaseCenter',
  data() {
    return {
      picUrl: '',
      dataTotal: 0,
      caseTypeList: [],
      caseTypeKey: [],
      caseTypeValue: [],
      queryData: {
        limit: 10,
        page: 1,
        title: ''
      },
      ceasCenterList: [],
      pictureBaseUrl: pictureBaseUrl
    }
  },
  created() {
    this.getCaseTypeList()
    this.getShowAdvert()
    // this.getDataList()
  },
  methods: {
    selectDetail(item) {
      this.$router.push({
        name: '/caseDetail',
        params: item
      })
    },
    getShowAdvert() {
      getShowAdvert({ type: '4' }).then(res => {
        if (res && res.code === 200) {
          this.picUrl = res.data[0].url
        }
      })
    },
    getDataList() {
      getCaseView(this.queryData).then(res => {
        if (res && res.code === 200) {
          this.dataTotal = res.data.total
          for (const record of res.data.records) {
            console.log(this.caseTypeKey.indexOf(record.caseType))
            console.log(this.caseTypeValue)
            this.ceasCenterList.push({
              name: this.caseTypeValue[this.caseTypeKey.indexOf(record.caseType)],
              imgUrl: record.coverFileId,
              title: record.customerName,
              remarks: record.introduction,
              tabs: record.keyWords.split('，'),
              id: record.id,
              updateTime: record.updateTime,
              viewCount: record.viewCount,
              textContent: record.caseIntroduce
            })
          }
        }
      })
    },
    getCaseTypeList() {
      this.caseTypeList = []
      getDictByDicKey({ dicKey: 'case' }).then(res => {
        if (res && res.code === 200) {
          for (const datum of res.data.data) {
            this.caseTypeList.push({
              key: datum.name,
              value: datum.key
            })
            this.caseTypeValue.push(datum.name)
            this.caseTypeKey.push(datum.key)
          }
          this.getDataList()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
