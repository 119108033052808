<template>
  <div class="zw-product">
    <ul>
      <li v-for="(item, index) in productArr" :key="index" style="cursor: pointer" class="zw-product-list" @click="jumpPage(item.path)">
        <div class="zw-hover">
          <span class="zw-product-round" />
          <p class="zw-product-title">{{ item.title }}</p>
        </div>
        <span style="color: rgba(34,34,34,.7);font: 400 13px/21px PingFangSC-Regular">{{ item.context }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { getProductMenu } from '@/js/product'

export default {
  name: 'Product',
  data() {
    return {
      productArr: []
    }
  },
  created() {
    this.getProductMenu()
  },
  methods: {
    getProductMenu() {
      this.productArr = []
      getProductMenu().then(res => {
        if (res && res.code === 200) {
          for (const datum of res.data) {
            this.productArr.push({
              title: datum.menuName,
              context: datum.describe,
              path: datum.path
            })
          }
        }
      })
    },
    jumpPage(path) {
      this.$router.push({
        path: path,
        query: {
          active: '2'
        }
      })
    }
  }
}
</script>

<style>
</style>
