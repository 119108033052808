import request from '@/utils/request'

export function getHonor(data) {
  return request({
    url: `/tbHonorQualification/getHonor`,
    method: 'get',
    params: data
  })
}

export function getDevelop(data) {
  return request({
    url: `/tbDevelopCourse/getDevelop`,
    method: 'get',
    params: data
  })
}
