<template>
  <div class="zw-home">
    <!--轮播图-->
    <carousel :type="'1'" />
    <!--产品-->
    <div class="zw-home-product">
      <div style="text-align: center">
        <p class="zw-home-product-title">安全可靠 · 持续创新的产品服务</p>
        <span style="font-size: 16px;padding: 10px">基于20多年的技术沉淀，为您提供管理软件开发与运维服务</span>
      </div>
      <ul class="zw-home-product-list">
        <li v-for="(item, index) in productList" :key="index">
          <div v-if="item.classShow==='even'" class="zw-home-product-list-item">
            <img :src="item.imgUrl" width="100%" alt="">
            <div style="padding: 10px 15px">
              <div class="zw-home-list-item_title">
                <span />
                <p
                  style="font-size: 22px;
                padding-left: 12px"
                >{{ item.title }}</p>
              </div>
              <p style="font-size: 14px;padding: 5px 0">{{ item.context }}</p>
              <!--              <el-button type="danger" plain size="mini">-->
              <!--                查看更多<i class="el-icon-d-arrow-right" />-->
              <!--              </el-button>-->
            </div>
          </div>
          <div
            v-if="item.classShow==='odd'"
            :style="{backgroundImage:'url('+ item.imgUrl +')'}"
            class="zw-home-product-list-itemOdd"
          >
            <div style="padding: 10px 15px">
              <div style="padding: 40px 0">
                <div class="zw-home-list-item_title">
                  <span />
                  <p style="font-size: 22px;padding-left: 12px">{{ item.title }}</p>
                </div>
                <p style="font-size: 14px;padding: 5px 0">{{ item.context }}</p>
              </div>
              <!--              <el-button class="depth" type="danger" plain size="mini">-->
              <!--                查看更多<i class="el-icon-d-arrow-right" />-->
              <!--              </el-button>-->
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!--客户案例-->
    <div class="zw-home-customer">
      <div style="text-align: center">
        <p class="zw-home-customer-title">全国200+客户的共同选择</p>
        <span style="font-size: 16px;padding: 10px 0">鑫众为完善的服务体系为企业数字化上云保驾护航
          <a style="color: #C7000B;padding-left: 10px;" @click="goCase">查看更多客户案例 <i class="el-icon-arrow-right" /></a>
        </span>
      </div>
      <div class="zw-home-customer-context">
        <div v-if="mainShowCaseObject !== null" style="margin: 5px 10px" class="zw-home-customer-context-left" :style="{backgroundImage:'url('+ pictureBaseUrl + mainShowCaseObject.coverFileId +')'}">
          <div class="context-left-content">
            <div style="color: #fff;padding-bottom: 20px">
              <p style="font-size: 26px;">{{ mainShowCaseObject.customerName }}</p>
              <span>{{ mainShowCaseObject.introduction }}</span>
            </div>
            <a style="color: #FFFFFF" @click="goCase">了解更多 <i class="el-icon-arrow-right" /></a>
          </div>
        </div>
        <ul class="zw-home-customer-context-right">
          <li v-for="(item, index) in customerList" :key="index">
            <div
              :style="{backgroundImage:'url('+ pictureBaseUrl + item.imgUrl +')'}"
              class="context-right-content"
            >
              <div style="padding: 20px 0">
                <p style="font-size: 24px;">{{ item.title }}</p>
                <span>{{ item.context }}</span>
              </div>
              <a style="color: #ffffff" @click="selectCaseDetail(item)">了解详情 <i class="el-icon-arrow-right" /></a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--    &lt;!&ndash;专项权威&ndash;&gt;-->
    <!--    <div class="zw-home-authority">-->
    <!--      <div class="zw-authority-heard">专项权威的奖项及认可</div>-->
    <!--      <ul class="zw-authority-content">-->
    <!--        <li v-for="(item,index) in authorityList" :key="index">-->
    <!--          <div />-->
    <!--        </li>-->
    <!--      </ul>-->
    <!--    </div>-->
    <!--客户信赖之选-->
    <div class="zw-home-trust">
      <div class="zw-trust-content">
        <!--        <span class="zw-trust-top" />-->
        <div class="zw-trust-centext">
          <div class="zw-trust-cent-title">客户信赖之选</div>
          <p class="zw-trust-cent-explain">各行业众多客户都选择鑫众为作为数字化转型升级的合作方</p>
        </div>
        <div class="zw-trust-power">
          <div
            style="display: flex;align-items: center;justify-content: center;
          background-color: #FFFFFF;width: 50%;padding: 10px 0;border: 1px solid #e72113"
          >
            <div style="width: 155px;">
              <img src="../assets/img/logo1.png" width="100%" alt="">
            </div>
          </div>
          <div style="background-color: #e72113;flex: 1;padding: 11px 0;">
            <div class="zw-trust-power-title">我们的实力</div>
          </div>
        </div>
        <div class="zw-trust-bottom">
          <ul class="zw-trust-list">
            <li>
              <div class="zw-trust-list-title">
                <span style="font-size: 56px">20</span>
                年
              </div>
              <div>
                <span class="zw-trust-list-context">
                  行业累计沉淀
                </span>
              </div>
            </li>
            <li style="margin: 0 120px">
              <div class="zw-trust-list-title">
                <span style="font-size: 56px">200</span>
                +
              </div>
              <div>
                <span class="zw-trust-list-context">
                  长期合作伙伴
                </span>
              </div>
            </li>
            <li>
              <div class="zw-trust-list-title">
                <span style="font-size: 56px">200</span>
                +
              </div>
              <div>
                <span class="zw-trust-list-context">
                  资深团队成员
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div>
          <el-button style="margin-top: 20px" class="depth" type="danger" plain size="mini">
            查看更多<i class="el-icon-arrow-right" />
          </el-button>
        </div>
      </div>
    </div>
    <!--新闻咨询-->
    <div class="zw-home-journalism">
      <div class="zw-journalism-title">
        <p style="font-weight: bold;font-size: 32px">新闻资讯</p>
        <div style="font-size: 16px;padding: 10px 0">获悉最新前沿资讯 洞察数字转型之路</div>
      </div>
      <div class="zw-journalism-content">
        <div v-if="mainShowNewsObject !== null" class="zw-journalism-main">
          <el-image
            :src="pictureBaseUrl + mainShowNewsObject.fileId"
            alt=""
            style="width: 100%;height: 260px;display: block"
            width="100%"
            height="260"
            fit="cover"
          />
          <div class="zw-journalism-main-explain">
            <div class="zw-explain-top" style="cursor: pointer" @click="selectNewsDetail(mainShowNewsObject)">
              <span />
              <p>{{ mainShowNewsObject.title }}</p>
            </div>
            <div class="zw-explain-center">
              {{ mainShowNewsObject.contentIntroduction }}
            </div>
            <div
              style="display: flex;justify-content: flex-end;
                padding-right: 30px;padding-bottom: 20px "
            >
              <el-button type="danger" class="depth" plain size="mini" @click="goNews">
                查看更多<i class="el-icon-arrow-right" />
              </el-button>
            </div>
          </div>
        </div>
        <ul class="zw-journalism-list">
          <li
            v-for="(item,index) in journalismList"
            :key="index"
            style="cursor: pointer"
            class="zw-journalism-list-item"
            @click="selectNewsDetail(item)"
          >
            <div>
              <el-image
                :src="pictureBaseUrl + item.fileId"
                style="width: 180px;height: 95px"
                width="180"
                height="95"
                alt=""
                fit="cover"
              />
            </div>
            <div class="zw-journalism-item-context">
              <p style="font-weight: bold">{{ item.title }}</p>
              <span>{{ item.context }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--我们的客户-->
    <div class="zw-home-oursCustomer">

      <ul class="zw-oursCustomer-List">
        <div class="zw-oursCustomer-title">
          我们的客户
        </div>
        <runningAway
          v-if="runningAwayFlag"
          :data-list="customerDataList"
        />
        <!--        <li v-for="(item,index) in oursCustomerList" :key="index">-->
        <!--          <img :src="item.imgUrl" width="200" alt="">-->
        <!--        </li>-->
      </ul>
    </div>
    <!--免费获取方案-->
    <div class="zw-home-programme">
      <div>
        <p style="font-size: 26px;margin-right: 40px">联系我们，即刻为你的企业量身定制解决方案</p>
        <el-button class="depth" type="danger" plain size="mini" @click="handleProgramme">
          免费获取方案
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsHome, getNewsMainShow } from '@/js/newsConsul'
import { getCaseView, getCaseMainShow } from '@/js/caseCenter'
import runningAway from '../components/runningAway'
import { getCustomerMarkShow } from '@/js/customerMark'
import { pictureBaseUrl } from '@/utils/request'
export default {
  name: 'HomePage',
  // 注册组件
  components: {
    runningAway
  },
  data() {
    return {
      runningAwayFlag: false,
      customerDataList: [],
      oursCustomerList: [
        {
          imgUrl: require('../assets/img/logo1.png')
        },
        {
          imgUrl: require('../assets/img/logo1.png')
        },
        {
          imgUrl: require('../assets/img/logo1.png')
        },
        {
          imgUrl: require('../assets/img/logo1.png')
        },
        {
          imgUrl: require('../assets/img/logo1.png')
        },
        {
          imgUrl: require('../assets/img/logo1.png')
        },
        {
          imgUrl: require('../assets/img/logo1.png')
        },
        {
          imgUrl: require('../assets/img/logo1.png')
        },
        {
          imgUrl: require('../assets/img/logo1.png')
        },
        {
          imgUrl: require('../assets/img/logo1.png')
        },
        {
          imgUrl: require('../assets/img/logo1.png')
        },
        {
          imgUrl: require('../assets/img/logo1.png')
        }
      ],
      authorityList: [{}, {}, {}, {}, {}],
      journalismList: [],
      pictureBaseUrl: pictureBaseUrl,
      productList: [
        {
          imgUrl: require('../assets/image/zhcz.png'),
          title: '智慧财政',
          classShow: 'even',
          context: '通过数字化智能解决方案，高效满足财政人力资源管理、人事工资、财政预算前置管理、内部控制等业务需求，提升业务效率，助力财政信息化、智慧化转型'
        },
        {
          imgUrl: require('../assets/image/szrl.jpg'),
          title: '大数据服务',
          classShow: 'odd',
          context: '提供可靠的数据治理、数据分析产品与服务，实现数据智能分析、BI大屏可视化，最大程度挖掘数据价值'
        },
        {
          imgUrl: require('../assets/image/znyw.jpg'),
          title: '智能运维',
          classShow: 'even',
          context: '通过系统监控、智能告警、问题鉴责、故障定位等功能，帮助用户实现系统及业务的自动化运维，提升应用性能和使用效率'
        },
        // 华为生态合作伙伴，
        {
          imgUrl: require('../assets/image/szsw.jpg'),
          title: '国产化服务',
          classShow: 'odd',
          context: '提供国产一体化配套解决方案'
        }
      ],
      customerList: [
        {
          imgUrl: require('../assets/img/alxwzfj.jpg'),
          title: '陕西省国库支付中心',
          context: '实现财务管理全员化、业务化、实时化、自动化、智能化、服务...'
        },
        {
          imgUrl: require('../assets/img/alxwzfj.jpg'),
          title: '陕西省国库支付中心',
          context: '实现财务管理全员化、业务化、实时化、自动化、智能化、服务...'
        },
        {
          imgUrl: require('../assets/img/alxwzfj.jpg'),
          title: '陕西省国库支付中心',
          context: '实现财务管理全员化、业务化、实时化、自动化、智能化、服务...'
        }
      ],
      mainShowNewsObject: null,
      mainShowCaseObject: null
    }
  },
  created() {
    this.getNewsDataList()
    this.getNewsMainShow()
    this.getCaseDataList()
    this.getCaseMainShow()
    this.getCustomerMarkShow()
  },
  methods: {
    async getCustomerMarkShow() {
      this.oursCustomerList = []
      this.oursCustomerList2 = []
      await getCustomerMarkShow().then(res => {
        if (res && res.code === 200) {
          this.customerDataList = res.data
          this.runningAwayFlag = true
          console.log(1111)
        }
      })
    },
    async getCaseMainShow() {
      await getCaseMainShow().then(res => {
        if (res && res.code === 200) {
          this.mainShowCaseObject = res.data
        }
      })
    },
    async getCaseDataList() {
      this.customerList = []
      await getCaseView({
        limit: 3,
        page: 1,
        mainShow: '1'
      }).then(res => {
        if (res && res.code === 200) {
          this.dataTotal = res.data.total
          for (const record of res.data.records) {
            this.customerList.push({
              imgUrl: record.coverFileId,
              title: record.customerName,
              remarks: record.introduction,
              tabs: record.keyWords.split('，'),
              id: record.id,
              updateTime: record.updateTime,
              viewCount: record.viewCount,
              textContent: record.caseIntroduce,
              context: record.introduction
            })
          }
        }
      })
    },
    selectCaseDetail(item) {
      this.$router.push({
        name: '/caseDetail',
        params: item,
        query: {
          active: '4'
        }
      })
    },
    async getNewsMainShow() {
      await getNewsMainShow().then(res => {
        if (res && res.code === 200) {
          this.mainShowNewsObject = res.data
          this.mainShowNewsObject.imgUrl = 'data:image/Jpeg;base64,' + this.mainShowNewsObject.fileId
        }
      })
    },
    selectNewsDetail(item) {
      this.$router.push({
        name: '/newsDetail',
        params: item,
        query: {
          active: '5'
        }
      })
    },
    goCase() {
      this.$router.push({
        path: '/caseCenter',
        query: {
          active: '4'
        }
      })
    },
    goNews() {
      this.$router.push({
        path: '/newsConsul',
        query: {
          active: '5'
        }
      })
    },
    async getNewsDataList() {
      await getNewsHome({
        limit: 4,
        page: 1,
        title: '',
        type: '1',
        mainShow: '1'
      }).then(res => {
        if (res && res.code === 200) {
          this.journalismList = []
          for (const record of res.data.records) {
            let context = ''
            if (record.pureText !== null && record.pureText !== '') {
              if (record.pureText.length > 54) {
                context = record.pureText.substr(0, 54) + '...'
              } else {
                context = record.pureText
              }
            }
            this.journalismList.push({
              imgUrl: 'data:image/Jpeg;base64,' + record.fileId,
              title: record.title,
              context: context.trimStart(),
              textContent: record.textContent,
              browse: record.viewCount,
              viewCount: record.viewCount,
              updateTime: record.updateTime,
              id: record.id,
              fileId: record.fileId
            })
          }
        }
      })
    },
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
