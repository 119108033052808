import Vue from 'vue'
import Router from 'vue-router'

import homePage from '@/views/homePage'
import getScheme from '@/views/getScheme'
import newsConsul from '@/views/newsConsul'
import aboutUs from '@/views/aboutUs'
import caseCenter from '@/views/caseCenter'
import solution from '@/views/solution'
import tailorism from '@/views/tailorism'
import recruit from '@/views/recruit'
import caseDetail from '@/views/components/caseDetail'
import newsDetail from '@/views/components/newsDetail'
import jobDetails from '@/views/components/jobDetails'
import delivery from '@/views/components/delivery' // 职务投递'
// 产品
import personnelLedger from '@/views/product/personnelLedger' // 人员台账
import BIProduct from '@/views/product/BIProduct' // BI产品
import stationLibrary from '@/views/product/stationLibrary' // 一站一库一平台
import wageInquiry from '@/views/product/wageInquiry' // 工资查询
import humanNuclear from '@/views/product/humanNuclear' // 人脸核身
import humanResources from '@/views/product/humanResources' // 人力资源综合管理平台
import personnelWage from '@/views/product/personnelWage' // 人身工资
import governance from '@/views/product/governance' // 陕西财政系统自治与数据治理项目
import legalInspection from '@/views/product/legalInspection' // 法检工资
import APM from '@/views/product/APM' // APM
import preprocess from '@/views/product/preprocess' // 内控系统
import SMSPlatform from '@/views/product/SMSPlatform' // 陕西税务12366短信平台
import electron from '@/views/product/electron' // 电子运维系统

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: 'homePage'
    },
    {
      path: '/homePage',
      name: 'homePage',
      component: homePage
    },
    {
      path: '/getScheme',
      name: 'getScheme',
      component: getScheme
    },
    {
      path: '/newsConsul',
      name: 'newsConsul',
      component: newsConsul
    },
    {
      path: '/aboutUs',
      name: 'aboutUs',
      component: aboutUs
    },
    {
      path: '/caseCenter',
      name: 'caseCenter',
      component: caseCenter
    },
    {
      path: '/solution',
      name: 'solution',
      component: solution
    },
    {
      path: '/tailorism',
      name: 'tailorism',
      component: tailorism
    },
    {
      path: '/recruit',
      name: '/recruit',
      component: recruit
    },
    {
      path: '/jobDetails',
      name: '/jobDetails',
      component: jobDetails
    },
    {
      path: '/newsDetail',
      name: '/newsDetail',
      component: newsDetail
    },
    {
      path: '/caseDetail',
      name: '/caseDetail',
      component: caseDetail
    },
    {
      path: '/delivery',
      name: '/delivery',
      component: delivery
    },
    {
      path: '/product',
      name: 'product',
      component: () => import('@/views/tailorism.vue'),
      children: [
        {
          path: 'personnelLedger', // 人员台账
          name: 'personnelLedger',
          // component: () => import('@/views/product/personnelLedger.vue')
          component: personnelLedger
        },
        {
          path: 'BIProduct', // BI 产品
          name: 'BIProduct',
          component: BIProduct
        },
        {
          path: 'stationLibrary', // 一站一库一平台
          name: 'stationLibrary',
          component: stationLibrary
        },
        {
          path: 'wageInquiry', // 工资查询
          name: 'wageInquiry',
          component: wageInquiry
        },
        {
          path: 'humanNuclear', // 人脸核身
          name: 'humanNuclear',
          component: humanNuclear
        },
        {
          path: 'personnelWage', // 人事工资
          name: 'personnelWage',
          component: personnelWage
        },
        {
          path: 'governance', // 陕西财政系统自治与数据治理项目
          name: 'governance',
          component: governance
        },
        {
          path: 'humanResources', // 人力资源综合管理平台
          name: 'humanResources',
          component: humanResources
        },
        {
          path: 'legalInspection', // 法检工资
          name: 'legalInspection',
          component: legalInspection
        },
        {
          path: 'APM', // APM
          name: 'APM',
          component: APM
        },
        {
          path: 'preprocess', // 内控系统
          name: 'preprocess',
          component: preprocess
        },
        {
          path: 'SMSPlatform', // 陕西税务12366短信平台
          name: 'SMSPlatform',
          component: SMSPlatform
        },
        {
          path: 'electron', // 电子运维系统
          name: 'electron',
          component: electron
        }
      ]
    }
  ]
})
