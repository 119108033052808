<template>
  <div class="zw-job-details">
    <div class="zw-details-top">
      <div class="zw-details-context">
        <div class="zw-details-left">
          <p style="font-size: 26px;color: #333333">{{ rowObj.position }}</p>
          <div style="display: flex;align-items: center;padding: 10px 0">
            <span v-if="rowObj.workNature === '1'">全职</span>
            <span v-if="rowObj.workNature === '2'">兼职</span>
            <span v-if="rowObj.workNature === '3'">实习</span>
            <el-divider direction="vertical" />
            <span>{{ rowObj.positionType }}</span>
            <el-divider direction="vertical" />
            <span>工作地点： {{ rowObj.workAddress }}</span>
            <el-divider direction="vertical" />
            <span>{{ rowObj.releaseTime }}发布</span>
            <el-divider direction="vertical" />
            <span>招聘人数：{{ rowObj.count }}</span>
          </div>
        </div>
        <div style="font-size: 32px;color: #C7000B;">
          {{ rowObj.salaryRange }}
        </div>
      </div>
    </div>
    <div class="zw-details-conter">
      <div style="width: 70%;">
        <p style="font-size: 16px;padding: 10px 0">岗位职责：</p>
        <div v-html="rowObj.jobResponsibility" />
      </div>
      <div style="width: 70%;margin-top: 30px">
        <p style="font-size: 16px;padding: 10px 0">任职要求：</p>
        <div v-html="rowObj.workRequirement" />
      </div>
    </div>
    <div class="zw-details-bottom">
      <el-button
        style="padding: 12px 50px;font-size: 18px"
        class="depth"
        type="danger"
        plain
        @click="handleDelivery"
      >
        立即投递
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobDetails',
  props: {
  },
  data() {
    return {
      rowObj: {}
    }
  },
  created() {
    this.rowObj = this.$route.query.rowJobObj
  },
  methods: {
    // 立即投递
    handleDelivery() {
      this.$router.push({
        path: 'delivery',
        query: {
          rowObj: this.$route.query.rowJobObj
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
