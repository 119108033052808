<template>
  <div>
    <div v-if="showRecruit" class="zw-recruit">
      <!--大图-->
      <div class="zw-recruit-bgImg" :style="{backgroundImage:'url('+ pictureBaseUrl + picUrl +')'}">
        <div
          class="zw-bgImg-context"
        >
          <div class="zw-bgImg-title">
            <div style="font-size: 40px;font-weight: bolder;">
              <span style="color: #cf3429">你好！</span>互联网新势力
            </div>
            <span style="font-size: 22px;padding: 10px 0;">
              Hello! New Internet forces
            </span>
          </div>
          <div
            class="zw-bgImg-search"
          >
            <el-input
              v-model="formQuery.searchWord"
              placeholder="请搜索职位关键词或岗位描述"
            >
              <div
                slot="append"
                class="el-icon-search"
                @click="getDataList"
              />
            </el-input>
          </div>
        </div>
      </div>
      <!--招聘内容-->
      <div class="zw-recruit-context">
        <div class="zw-recruit-condition">
          <div class="zw-condition-left">
            <p>
              <span style="font-size: 48px;">
                {{ stationNum }}</span>个在招职位
            </p>
            <div style="margin-top: 15px;">
              <el-input v-model="formQuery.searchWord" placeholder="请搜索职位关键词或岗位描述">
                <el-button
                  slot="append"
                  style="font-size: 26px"
                  icon="el-icon-search"
                  @click="getDataList"
                />
              </el-input>
            </div>
          </div>
          <div class="zw-condition-right">
            <ul style="margin-bottom: 0">
              <!--工作地点-->
              <!--              <li class="zw-condition-context">-->
              <!--                <div>-->
              <!--                  工作地点 <i style="margin: 0 20px;color: #333333" class="el-icon-arrow-right" />-->
              <!--                </div>-->
              <!--                <el-radio-group-->
              <!--                  v-model="formQuery.workAddress"-->
              <!--                  size="mini"-->
              <!--                  @input="getDataList"-->
              <!--                >-->
              <!--                  <el-radio-button-->
              <!--                    :label="null"-->
              <!--                  >-->
              <!--                    全部-->
              <!--                  </el-radio-button>-->
              <!--                  <el-radio-button-->
              <!--                    v-for="(item, index) in siteList"-->
              <!--                    :key="index"-->
              <!--                    :label="item"-->
              <!--                  />-->
              <!--                </el-radio-group>-->
              <!--              </li>-->
              <!--职位类型-->
              <li class="zw-condition-context" style="margin: 15px 0">
                <div>
                  职位类型 <i style="margin: 0 20px;color: #333333" class="el-icon-arrow-right" />
                </div>
                <el-radio-group
                  v-model="formQuery.positionType"
                  size="mini"
                  @input="getDataList"
                >
                  <el-radio-button
                    :label="null"
                  >
                    全部
                  </el-radio-button>
                  <el-radio-button
                    v-for="(item, index) in jobList"
                    :key="index"
                    :label="item"
                  />
                </el-radio-group>
              </li>
              <!--发布时间-->
              <li class="zw-condition-context">
                <div>
                  发布时间 <i style="margin: 0 20px;color: #333333" class="el-icon-arrow-right" />
                </div>
                <el-radio-group
                  v-model="formQuery.createTimeScope"
                  size="mini"
                  @input="getDataList"
                >
                  <el-radio-button
                    :label="null"
                  >
                    全部
                  </el-radio-button>
                  <el-radio-button
                    v-for="(item, index) in timeList"
                    :key="index"
                    :label="item"
                  />
                </el-radio-group>
              </li>
            </ul>
          </div>
        </div>
        <!--列表展示-->
        <ul class="zw-recruit-list">
          <li
            v-for="(item, index) in recruitList"
            :key="index"
            style="cursor: pointer"
            @click="handleRecruit(item)"
          >
            <div class="zw-recruit-list-left">
              <p style="font-size: 18px;color: #333333">{{ item.position }}</p>
              <div style="display: flex;align-items: center;padding: 10px 0">
                <span v-if="item.workNature === '1'" style="display: inline-block;width: 40px">全职</span>
                <span v-if="item.workNature === '2'" style="display: inline-block;width: 40px">兼职</span>
                <span v-if="item.workNature === '3'" style="display: inline-block;width: 40px">实习</span>
                <el-divider direction="vertical" />
                <span style="display: inline-block;width: 60px">{{ item.positionType }}</span>
                <el-divider direction="vertical" />
                <div>
                  <span
                    style="overflow:hidden;
                    white-space: nowrap;
                    max-width: 180px;
                    text-overflow: ellipsis;"
                  >工作地点：{{ item.workAddress }}</span>
                </div>
              </div>
              <div style="display: flex">
                <p>发布时间： {{ item.releaseTime }}</p>
                <p style="margin-left: 25px">招聘人数： {{ item.count }}</p>
              </div>
            </div>
            <div style="font-size: 24px;color: #C7000B;width:120px;text-align: right;min-width: 100px;margin-left: -50px;margin-top: -6px">
              {{ item.salaryRange }}
            </div>
          </li>
        </ul>
        <!--分页-->
        <div style="display: flex;justify-content: flex-end;margin: 50px 0 20px 0">
          <el-pagination
            v-show="total>0"
            background
            :current-page.sync="formQuery.page"
            :page-size="formQuery.limit"
            layout="prev, pager, next, jumper"
            :total="total"
            @current-change="getDataList()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecruitment, getSearchType } from '@/js/recruit'
import { getShowAdvert } from '@/js/carousel'
import { pictureBaseUrl } from '@/utils/request'
export default {
  name: 'Recruit',
  components: {
  },
  data() {
    return {
      pictureBaseUrl: pictureBaseUrl,
      picUrl: '',
      showRecruit: true,
      stationNum: 60,
      total: 0,
      formQuery: {
        page: 1,
        limit: 10,
        searchWord: '',
        workAddress: null,
        positionType: null,
        createTimeScope: null
      },
      rowObj: {},
      siteArr: [],
      recruitList: [],
      siteList: [],
      jobList: [],
      timeList: ['一周以内', '一个月以内', '三个月以内']
    }
  },
  created() {
    this.getShowAdvert()
    this.getSearchType()
    this.getDataList()
  },
  methods: {
    getShowAdvert() {
      getShowAdvert({ type: '7' }).then(res => {
        if (res && res.code === 200) {
          this.picUrl = res.data[0].url
        }
      })
    },
    getDataList() {
      this.recruitList = []
      getRecruitment(this.formQuery).then(res => {
        if (res && res.code === 200) {
          this.total = res.data.total
          this.stationNum = res.data.total
          this.recruitList = res.data.records
        }
      })
    },
    handleRecruit(obj) {
      this.$router.push({
        path: '/jobDetails',
        query: {
          rowJobObj: obj
        }
      })
    },
    getSearchType() {
      this.siteList = []
      this.jobList = []
      getSearchType().then(res => {
        if (res && res.code === 200) {
          this.siteList = res.data.workAddressList
          this.jobList = res.data.positionTypeList
        }
      })
    }
  }
}
</script>

<style>
  .active{
    color: #C7000B;
  }

</style>
