<template>
  <div class="zw-product-four-template">
    <!--    背景图-->
    <div
      :style="{backgroundImage:'url('+ bgImgUrl +')'}"
      class="zw-four-bg"
    >
      <div class="zw-four-bg-tile">
        <!--        style="color: #3a7bff"-->
        <div>BI产品</div>
        <span>图形化，可视化的数据分析系统</span>
      </div>
    </div>
    <div class="zw-four-bg-introduc">
      <div
        style="display: flex;justify-content: center;align-items: center;
        margin-bottom: 20px"
      >
        <img src="../../assets/image/product/fourLeft.png" alt="">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
         padding: 0 20px"
        >系统简介</div>
        <img src="../../assets/image/product/fourRight.png" alt="">
      </div>
      <div
        style="display: flex;padding: 50px 0 0;width: 100%;
      justify-content: center;flex-direction: column"
      >
        <div
          style="font-size: 16px;color: #000000;
        line-height: 26px"
        >
          提供拖曳式的操作，让用户能够随时更改观察数据的维度、指标，将数据以丰富的图文方式，进行迅速、直观的表达，
          同时借助联动、 钻取、链接等交互操作，发现数据内部的细节规律，让用户能够在操作交互过程中与数据进行直接、
          实时的对话，探索潜藏的数据规律，深度诠释“过去发生了什么，为什么会发生、未来会发生什么（AI)”。 解决数据
          可视化和数据之间上钻、下钻、数据可用的问题。
        </div>
        <el-divider />
      </div>
    </div>
    <div class="zw-four-bg-trait">
      <div
        style="display: flex;justify-content: center;align-items: center;
        margin-bottom: 20px"
      >
        <img src="../../assets/image/product/fourLeft.png" alt="">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
          padding: 0 20px"
        >系统特点</div>
        <img src="../../assets/image/product/fourRight.png" alt="">
      </div>
      <div class="zw-trait-context">
        <ul>
          <li v-for="(item, index) in traitList" :key="index">
            <el-card style="width: 100%" shadow="always">
              <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
                <div style="text-align: center;padding-right: 20px;">
                  {{ item.text }}
                </div>
                <img :src="item.imgUrl" width="100px" height="100px" alt="">
              </div>

            </el-card>
          </li>
        </ul>
      </div>
    </div>
    <!--免费获取方案-->
    <div
      :style="{backgroundImage:'url('+ fontImg +')'}"
      class="zw-four-footer"
    >
      <div
        style="display: flex;align-items: center;height: 100%;
      justify-content: center;flex-direction: column"
      >
        <div style="font-size: 28px;font-weight: bold;color: #000000;padding-bottom: 30px">
          即刻为你的企业定制解决方案
        </div>
        <el-button size="small" type="primary" @click="handleProgramme">免费获取方案</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BIProduct',
  data() {
    return {
      bgImgUrl: require('../../assets/image/product/fourBg.jpg'),
      fontImg: require('../../assets/image/product/fourfoot.jpg'),
      traitList: [
        {
          imgUrl: require('../../assets/image/product/four01.png'),
          text: '开发快速，一键导入数据可快速的生成一个移动报表，具有高大上的一种效果；'
        },
        {
          imgUrl: require('../../assets/image/product/four02.png'),
          text: '能够大幅降低数据的获取和处理成本、提升数据使用效率；'
        },
        {
          imgUrl: require('../../assets/image/product/four03.png'),
          text: '借助可视化、交互式的操作，可以高效支持业务的分析及发展；'
        },
        {
          imgUrl: require('../../assets/image/product/four04.png'),
          text: '是一个图形化，可视化的数据分析系统，通过简单配置，即可完成数据分析展示，' +
              '而不需要额外的技术开发。'
        }
      ]
    }
  },
  methods: {
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
