<template>
  <!--时间线-->
  <div v-if="showFlag" class="timeLine" style="overflow: hidden;">
    <div style="display: flex;align-items: flex-end">
      <div class="my_timeline_prev" @click="moveLeft">
        <div style="height: 45px;width: 45px">
          <img
            width="100%"
            src="../../public/static/按钮_箭头向左_o.png"
            class="my_timeline_node"
          >
        </div>
        <!--        <div class="my_timeline_item_line" />-->
        <!--      <div class="my_timeline_item_content" style="color: rgba(0,0,0,0);">上</div>-->
      </div>
      <div class="ul_box">
        <ul ref="mytimeline" class="my_timeline" style="margin-left: 10px;">
          <li
            v-for="(item,index) in timeLineArr"
            :key="index"
            class="my_timeline_item"
          >
            <!--            标注-->
            <div class="my_timeline_item_content" :style="{color: item.color, fontSize: item.fontsize + 'px'}">
              {{ item.timestamp }}
            </div>
            <div style="display: flex;align-items: center">
              <div
                class="my_timeline_node"
                :style=" {backgroundColor: item.bgcolor, width: item.size + 'px',
                          height: item.size + 'px',minWidth: item.size+'px',maxWidth: item.size+'px'}"
                :class="{active: index <= timeIndex}"
                @click="changeActive(index)"
              />
              <!--线-->
              <div
                class="my_timeline_item_line"
                :class="{activeLine: index+1 <= timeIndex}"
              />
            </div>
          </li>
        </ul>
      </div>
      <div class="my_timeline_next" @click="moveRight">
        <img
          src="../../public/static/按钮-箭头向右_o.png"
          style="width: 45px;height: 45px"
          class="my_timeline_node"
        >
        <!--<div class="my_timeline_item_content" style="color: rgba(0,0,0,0);">下</div>-->
      </div>
    </div>
    <!--     内容展示-->
    <div class="content" style="margin-top: 30px;margin-left: 50px">
      <div style="display: flex">
        <span style="color:#006fdf;">● &nbsp;</span>
        <p class="tit">{{ timeLineArr[timeIndex].year }}</p>
      </div>
      <div style="display: flex">
        <span style="color:#006fdf;">● &nbsp;</span>
        <p class="tit">{{ timeLineArr[timeIndex].title }}</p>
      </div>
      <div style="display: flex">
        <span style="color:#006fdf;">● &nbsp;</span>
        <p>{{ timeLineArr[timeIndex].info }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getDevelop } from '@/js/aboutUs'

export default {
  name: 'TimeLine',
  data() {
    return {
      showFlag: false,
      timeIndex: 0,
      timeLineCount: 0,
      timeLineArr: [],
      timeLineList: []
    }
  },
  created() {
    this.getDevelop()
  },
  methods: {
    getDevelop() {
      this.timeLineArr = []
      getDevelop().then(res => {
        if (res && res.code === 200) {
          for (var i = 0; i < res.data.length; i++) {
            this.timeLineList.push({
              timestamp: res.data[i].year + '年',
              color: '#999',
              fontsize: 18,
              size: '28',
              bgcolor: '#e4e7ed',
              icon: 'el-iconprev',
              year: res.data[i].year,
              info: res.data[i].content,
              title: res.data[i].title
            })
          }
          this.timeLineArr = this.timeLineList.slice(this.timeLineCount * 6, this.timeLineCount * 6 + 6)
          this.showFlag = true
        }
      })
    },
    changeActive(index) {
      this.timeIndex = index
    },
    moveLeft() {
      this.timeLineCount--
      this.timeIndex = 0
      if (this.timeLineCount <= 0) {
        this.timeLineCount = 0
        this.timeLineArr = this.timeLineList.slice(this.timeLineCount * 6, this.timeLineCount * 6 + 6)
      } else {
        this.timeLineArr = this.timeLineList.slice(this.timeLineCount * 6 - 1, this.timeLineCount * 6 + 5)
      }
      // if (this.timeIndex === 0) {
      //   this.timeIndex = 0
      // } else {
      //   this.timeIndex--
      // }
    },
    moveRight() {
      this.timeLineCount++
      // this.timeIndex = 0
      // console.log(this.timeLineCount)
      if (this.timeLineCount <= Math.floor(this.timeLineList.length / 6)) {
        this.timeIndex = 0
        this.timeLineCount = Math.floor(this.timeLineList.length / 6)
        this.timeLineArr = this.timeLineList.slice(this.timeLineCount * 6 - 1)
      } else {
        this.timeLineCount = Math.floor(this.timeLineList.length / 6)
        this.timeLineArr = this.timeLineList.slice(this.timeLineCount * 6 - 1, this.timeLineCount * 6 + 5)
      }
      // this.timeLineCount--
      // if (this.timeIndex === (this.timeLineList.length - 1)) {
      //   this.timeIndex = this.timeLineList.length - 1
      // } else {
      //   this.timeIndex++
      // }
    }
  }
}
</script>

<style scoped>
.my_timeline_prev, .my_timeline_next {
  display: flex;
  background-color: #fff;
  cursor: pointer;
}
.my_timeline{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.my_timeline_next {
  width: 45px;
  height: 45px;
}
.ul_box {
  width: 100%;
  height: 70px;
  display: inline-block;
  margin-top: 2px;
  overflow: hidden;
}
.my_timeline_item {
  display: inline-block;
  width: 100%;
  min-width: 100px;
}
.my_timeline_node {
  box-sizing: border-box;
  border-radius: 50%;
  cursor: pointer;
}
.my_timeline_node.active {
  background-color: #fff !important;
  border: 6px solid #C7000B;
}
.activeLine{
  width: 100%;
  border-top: 2px solid #C7000B !important;
  border-left: none;
}
.my_timeline_item_line {
  width: 100%;
  border-top: 2px solid #E4E7ED;
  border-left: none;
}
.my_timeline_item_content {
  margin: 10px 0 0 0;
}
</style>
