<template>
  <div id="app">
    <el-container>
      <el-header style="padding: 0">
        <navigationTop />
      </el-header>
      <el-main>
        <router-view />
        <navigationButtom />
      </el-main>
      <!--      固定在整个页面-->
      <fixedPosition />
    </el-container>
  </div>
</template>

<script>
import navigationTop from '@/components/navigationTop'
import navigationButtom from '@/components/navigationButtom'
import fixedPosition from '@/components/fixedPosition'
export default {
  name: 'App',
  components: {
    navigationTop,
    navigationButtom,
    fixedPosition
  },
  data() {
    return {
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
  }
}
</script>

<style lang="scss">
#app,.el-container{
  height: 100%;
  .el-header{
    padding: 0;
  }
}
</style>
