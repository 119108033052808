import axios from 'axios'
import store from '@/store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
// import { MessageBox } from 'element-ui'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 96000 // 请求超时时间
})
const pictureBaseUrl = process.env.VUE_APP_API_BASE_URL + '/tbNewsMessage/getPictureById?fileId='
// 异常拦截处理器
const errorHandler = (error) => {
  console.log('error')
  console.log(error)
  if (error.response) {
    // // debugger
    // const data = error.response.data
    // // 从 localstorage 获取 token
    // const token = storage.get(ACCESS_TOKEN)
    // if (error.response.status === 403) {
    //   notification.error({
    //     message: 'Forbidden',
    //     description: data.message
    //   })
    // }
    // if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
    //   notification.error({
    //     message: 'Unauthorized',
    //     description: 'Authorization verification failed'
    //   })
    //   if (token) {
    //     store.dispatch('Logout').then(() => {
    //       setTimeout(() => {
    //         window.location.reload()
    //       }, 1500)
    //     })
    //   }
    // }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if (response.data && response.data.code === '401') {
    notification.error({
      message: '提示信息',
      description: response.data.msg
    })
    store.dispatch('Logout').then(() => {
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    })
  }
  // if (response.data && response.data.code === '401') {
  //   console.log('401')
  //   console.log(response)
  //   MessageBox.confirm('登录超时，您可以取消停留在此页面，或重新登录', '确认退出', {
  //     confirmButtonText: '重新登录',
  //     cancelButtonText: '取消',
  //     type: 'warning'
  //   }).then(() => {
  //     store.dispatch('Logout').then(() => {
  //       setTimeout(() => {
  //         window.location.reload()
  //       }, 1500)
  //     })
  //   })
  // }
  // console.log('response')
  // console.log(response)
  return response.data
}, errorHandler)

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios,
  pictureBaseUrl
}
