<template>
  <div class="zw-product-four-template">
    <!--    背景图-->
    <div
      :style="{backgroundImage:'url('+ bgImgUrl +')'}"
      class="zw-four-bg"
    >
      <div class="zw-four-bg-tile">
        <div>APM/NPM</div>
        <span>应用性能监控、问题快速定位排障</span>
      </div>
    </div>
    <div class="zw-four-bg-introduc">
      <div
        style="display: flex;justify-content: center;align-items: center;
        margin-bottom: 20px"
      >
        <img src="../../assets/image/product/fourLeft.png" alt="">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
         padding: 0 20px"
        >系统简介</div>
        <img src="../../assets/image/product/fourRight.png" alt="">
      </div>
      <div
        style="display: flex;padding: 50px 0 0;width: 100%;
      justify-content: center;flex-direction: column"
      >
        <div
          style="font-size: 16px;color: #000000;
        line-height: 26px"
        >
          APM：通过部署应用性能监控系统实现对财政云核心业务应用监控，从代码级交易追踪、数据库语句监控、参数关联监控入手，
          对国库支付系统微服务组件、门户系统、电子凭证库等微服务架构下的应用性能监控的应用节点以及省本级相关应用的内外网监
          控，让运维人员掌握系统的运行性能，为业务系统日常运维等工作提供技术支撑，以满足内外网及微服务架构下的应用性能监控
          需求。 NPM：系统通过采用专利技术的DT引擎，利用业界领先的抓包时候短，通过流量镜像的方式实现了包括网络性能监控、
          业务性能监控、智能告警、问题鉴责、大屏数据监控、流量追溯、流量数据分析、业务交易追踪、服务器关联关系分析及业务关
          联分析等网络、应用及业务分析功能，通过这些功能的综合利用，能够帮助用户完成网络及应用问题的快速鉴责与排障、故障时
          段数据的追溯与取证、关键业务交易的追踪审计等网络及业务运维核心工作，是新一代的网络应用性能管理工具。解决网络故障
          和应用问题定位。
        </div>
        <el-divider />
      </div>
    </div>
    <div class="zw-four-bg-trait">
      <div
        style="display: flex;justify-content: center;align-items: center;
        margin-bottom: 20px"
      >
        <img src="../../assets/image/product/fourLeft.png" alt="">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
          padding: 0 20px"
        >系统特点</div>
        <img src="../../assets/image/product/fourRight.png" alt="">
      </div>
      <div class="zw-trait-context">
        <ul>
          <li v-for="(item, index) in traitList" :key="index">
            <el-card style="width: 100%" shadow="always">
              <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
                <div style="text-align: center;padding-right: 20px;">
                  {{ item.text }}
                </div>
                <img :src="item.imgUrl" width="100px" height="100px" alt="">
              </div>
            </el-card>
          </li>
        </ul>
      </div>
    </div>
    <!--免费获取方案-->
    <div
      :style="{backgroundImage:'url('+ fontImg +')'}"
      class="zw-four-footer"
    >
      <div
        style="display: flex;align-items: center;height: 100%;
      justify-content: center;flex-direction: column"
      >
        <div style="font-size: 28px;font-weight: bold;color: #000000;padding-bottom: 30px">
          即刻为你的企业定制解决方案
        </div>
        <el-button size="small" type="primary" @click="handleProgramme">免费获取方案</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'APM',
  data() {
    return {
      bgImgUrl: require('../../assets/image/product/fourBg.jpg'),
      fontImg: require('../../assets/image/product/fourfoot.jpg'),
      traitList: [
        {
          imgUrl: require('../../assets/image/product/four01.png'),
          text: '运维：快速定位故障，查找问题'
        },
        {
          imgUrl: require('../../assets/image/product/four02.png'),
          text: '研发：优化性能瓶颈及业务上线周期'
        },
        {
          imgUrl: require('../../assets/image/product/four03.png'),
          text: '运营：实时掌握用户体验和关键业务关系'
        },
        {
          imgUrl: require('../../assets/image/product/four04.png'),
          text: '省时间、省人力、加快市场节奏，提升研发能力，提升运维能力，让企业健康成长'
        }
      ]
    }
  },
  methods: {
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
