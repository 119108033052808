import request from '@/utils/request'

// 基础信息列表
export function getNews(data) {
  return request({
    url: `/tbNewsMessage/getNews`,
    method: 'get',
    params: data
  })
}

// 基础信息列表
export function getNewsHome(data) {
  return request({
    url: `/tbNewsMessage/getNewsHome`,
    method: 'get',
    params: data
  })
}

// 基础信息列表
export function addViewCount(data) {
  return request({
    url: `/tbNewsMessage/addViewCount`,
    method: 'get',
    params: data
  })
}

export function getContentById(data) {
  return request({
    url: `/tbNewsMessage/getContentById`,
    method: 'get',
    params: data
  })
}

// 基础信息列表
export function getNewsMainShow(data) {
  return request({
    url: `/tbNewsMessage/getNewsMainShow`,
    method: 'get',
    params: data
  })
}
