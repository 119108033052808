import request from '@/utils/request'

export function getRecruitment(data) {
  return request({
    url: `/tbRecruitment/getRecruitment`,
    method: 'get',
    params: data
  })
}

export function getSearchType(data) {
  return request({
    url: `/tbRecruitment/getSearchType`,
    method: 'get',
    params: data
  })
}
