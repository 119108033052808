<template>
  <div class="zw-aboutus">
    <!--大图-->
    <div class="zw-aboutus-bgImg" :style="{backgroundImage:'url('+ pictureBaseUrl + picUrl +')'}">
      <div class="zw-aboutus-bgImg-content">
        <p style="color: #ffffff">关于我们</p>
        <span style="color: #ffffff;margin-top: 10px">一切为了客户 以客户为中心</span>
      </div>
    </div>
    <!--导航栏 -->
    <div class="zw-aboutus-context">

      <el-tabs v-model="activeName" :stretch="true">
        <el-tab-pane label="公司介绍" name="1">
          <ul class="zw-aboutus-list">
            <!--公司介绍-->
            <li class="zw-aboutus-list-corp">
              <p class="zw-corp-title">鑫众为软件：专注信息化建设、智能化转型的管理软件供应商</p>
              <span class="zw-corp-division" />
              <ul class="zw-corp-context">
                <li v-for="(item, index) in corpList" :key="index">
                  {{ item.context }}
                </li>
              </ul>
              <div style="display: flex;justify-content: center">
                <img src="../assets/img/corpImg.png" width="50%" alt="">
              </div>
            </li>
            <li class="zw-aboutus-list-brief">
              <ul class="zw-brief-list">
                <li>
                  <span class="zw-brief-list-title">20年</span>
                  <div>
                    <span class="zw-brief-list-context">
                      累计行业沉淀
                    </span>
                  </div>
                </li>
                <el-divider direction="vertical" />
                <li>
                  <span class="zw-brief-list-title" style="">200+</span>
                  <div>
                    <span class="zw-brief-list-context">
                      长期合作伙伴
                    </span>
                  </div>
                </li>
                <el-divider direction="vertical" />
                <li>
                  <span class="zw-brief-list-title">200+</span>
                  <div>
                    <span class="zw-brief-list-context">
                      资深团队成员
                    </span>
                  </div>
                </li>
              </ul>
            </li>
            <!--服务理念-->
            <li class="zw-aboutus-list-idea">
              <div class="zw-idea-title">服务理念</div>
              <div class="zw-idea-list">
                <div class="zw-idea-left">
                  <img src="../assets/img/yxfwkhzs.png" width="50%" alt="">
                  <div class="zw-idea-left-title">
                    <p>一切为了客户</p>
                    <p>以客户为中心</p>
                  </div>
                  <span>我们通过更专业的服务，做到积极响应、快速行动、首问负责、跟踪落实彻底解决。</span>
                </div>
                <ul class="zw-idea-right">
                  <li v-for="(item,index) in ideaList" :key="index">
                    <img :src="item.imgUrl" alt="">
                    <p>{{ item.title }}</p>
                    <span>{{ item.context }}</span>
                  </li>
                </ul>
              </div>
            </li>
            <!--发展历程-->
            <li class="zw-aboutus-list-develop">
              <div class="zw-develop-title">发展历程</div>
              <ul class="zw-develop-context">
                <timeLine />
              </ul>
              <!--              <div class="zw-develop-btn">-->
              <!--                <el-button-->
              <!--                  style="width: 180px"-->
              <!--                  type="info"-->
              <!--                  plain-->
              <!--                  icon="el-icon-arrow-left"-->
              <!--                  @click="handleLastYear"-->
              <!--                >上一年</el-button>-->
              <!--                <el-button-->
              <!--                  style="width: 180px"-->
              <!--                  type="info"-->
              <!--                  plain-->
              <!--                  @click="handleNextYear"-->
              <!--                >下一年-->
              <!--                  <i class="el-icon-arrow-right el-icon&#45;&#45;right" />-->
              <!--                </el-button>-->
              <!--              </div>-->
            </li>
            <!--荣誉资质-->
            <li class="zw-aboutus-honor">
              <div class="zw-honor-title">荣誉资质</div>
              <div class="zw-honor-carousel">
                <el-carousel trigger="click" height="260px" indicator-position="outside" :interval="-1">
                  <el-carousel-item v-for="(item, index) in honorList" :key="index">
                    <ul class="zw-carousel-list">
                      <li v-for="(itemChild, indexChild) in item" :key="indexChild">
                        <div>
                          <el-image :src="pictureBaseUrl + itemChild.imgUrl" style="height: 140px;" alt="" :preview-src-list="srcList" />
                        </div>
                        <span style="padding-top: 20px">
                          {{ itemChild.name }}
                        </span>
                      </li>
                    </ul>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </li>
            <!--联系我们-->
            <li class="zw-aboutus-contact">
              <div class="zw-contact-title">联系我们</div>
              <div class="zw-contact-context">
                <div class="zw-contact-left">
                  <img src="../assets/img/lxwmImg.png" width="100%" alt="">
                </div>
                <ul class="zw-contact-right">
                  <li>
                    公司地址: <span>西安市丈八一路一号汇鑫IBC(A座)806室</span>
                  </li>
                  <li>
                    联系电话: <span>029-89181990</span>
                  </li>
                  <li>
                    公司邮箱: <span>info@zhongway.com</span>
                  </li>
                  <li>
                    公司邮编: <span>710065</span>
                  </li>
                  <li>
                    公司网址: <span>www.zhongway.com</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="服务理念" name="2">
          <ul class="zw-aboutus-list">
            <!--服务理念-->
            <li class="zw-aboutus-list-idea">
              <div class="zw-idea-title">服务理念</div>
              <div class="zw-idea-list">
                <div class="zw-idea-left">
                  <img src="../assets/img/yxfwkhzs.png" width="50%" alt="">
                  <p>用心服务  客户至上</p>
                  <span>我们通过更专业的服务，做到积极响应、快速行动、首问负责、跟踪落实彻底解决。</span>
                </div>
                <ul class="zw-idea-right">
                  <li v-for="(item,index) in ideaList" :key="index">
                    <img :src="item.imgUrl" alt="">
                    <p>{{ item.title }}</p>
                    <span>{{ item.context }}</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="发展历程" name="3">
          <ul class="zw-aboutus-list">
            <!--发展历程-->
            <li class="zw-aboutus-list-develop">
              <div class="zw-develop-title">发展历程</div>
              <ul class="zw-develop-context">
                <timeLine />
              </ul>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="荣誉资质" name="4">
          <ul class="zw-aboutus-list">
            <!--荣誉资质-->
            <li class="zw-aboutus-honor">
              <div class="zw-honor-title">荣誉资质</div>
              <div class="zw-honor-carousel">
                <el-carousel trigger="click" height="260px" indicator-position="outside" :interval="-1">
                  <el-carousel-item v-for="(item, index) in honorList" :key="index">
                    <ul class="zw-carousel-list">
                      <li v-for="(itemChild, indexChild) in item" :key="indexChild">
                        <div>
                          <el-image :src="pictureBaseUrl + itemChild.imgUrl" style="height: 140px;" alt="" :preview-src-list="srcList" />
                        </div>
                        <span style="padding-top: 20px">
                          {{ itemChild.name }}
                        </span>
                      </li>
                    </ul>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="联系我们" name="5">
          <ul class="zw-aboutus-list">
            <!--联系我们-->
            <li class="zw-aboutus-contact">
              <div class="zw-contact-title">联系我们</div>
              <div class="zw-contact-context">
                <div class="zw-contact-left">
                  <img src="../assets/img/lxwmImg.png" width="100%" alt="">
                </div>
                <ul class="zw-contact-right">
                  <li>
                    公司地址: <span>西安市丈八一路一号汇鑫IBC(A座)806室</span>
                  </li>
                  <li>
                    联系电话: <span>029-89181990</span>
                  </li>
                  <li>
                    公司邮箱: <span>info@zhongway.com</span>
                  </li>
                  <li>
                    公司邮编: <span>710065</span>
                  </li>
                  <li>
                    公司网址: <span>www.zhongway.com</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getHonor } from '@/js/aboutUs'
import timeLine from '../components/timeLine'
import { getShowAdvert } from '@/js/carousel'
import { pictureBaseUrl } from '@/utils/request'
export default {
  name: 'AboutUs',
  // 注册组件
  components: {
    timeLine
  },
  data() {
    return {
      srcList: [],
      picUrl: '',
      pictureBaseUrl: pictureBaseUrl,
      activeName: '1',
      active: 0,
      developStep: 0,
      developList: [],
      ideaList: [
        {
          imgUrl: require('../assets/img/ssxyImg.png'),
          title: '7×24小时实时响应',
          context: '为您提供全天候、不间断的实时响应服务，用最短的时间、最优的团队、最佳方案去帮助您！'
        },
        {
          imgUrl: require('../assets/img/yczcfwImg.png'),
          title: '远程支持服务',
          context: '通过热线电话、微信、远程协助等服务方式为客户提供产品资讯、应用答疑、投诉受理等服务支持！'
        },
        {
          imgUrl: require('../assets/img/xczcfwImg.png'),
          title: '现场驻场服务',
          context: '无论您身在何处，我们都能提供专人现场驻场服务，用最短的距离，帮助您解决使用过程中的任何问题！'
        },
        {
          imgUrl: require('../assets/img/lsdzfwImg.png'),
          title: '量身定制服务',
          context: '根据您的需求，为您提供专属的量身定制服务，帮助您打造最优产品解决方案及配套服务方案！'
        }
      ],
      corpList: [
        // 同华为建立了生态合作伙伴关系，
        {
          context: '陕西鑫众为软件有限公司是2004 年 7 月注册成立于古都西安，是一家集设计、研发、销售和运维服务为核心的高新技术企业。公司致力于成为中国领先的财政数字化方案提供商，拥有三十多项自主知识产权，是财政部预算管理一体化系统人员信息管理模块开发商、陕西预算管理一体化系统建设参建单位、陕西数字财政项目承建商、陕西预算管理一体化核心应用系统全省运维服务商。'
        },
        {
          context: '鑫众为软件先后承担了陕西财政云核心业务应用系统市县推广-网络与应用性能管理平台、陕西财政云预算管理一体化系统人员台账管理模块、陕西财政数据标准化规范管理平台、陕西财政云外部系统接口管理平台、陕西财政预算单位前置处理系统建设项目、陕西直达资金中间库、陕西数字财政等项目的开发实施和运维工作。'
        }
      ],
      honorList: []
    }
  },
  created() {
    this.getShowAdvert()
    this.getHonor()
  },
  methods: {
    getShowAdvert() {
      getShowAdvert({ type: '6' }).then(res => {
        if (res && res.code === 200) {
          this.picUrl = res.data[0].url
        }
      })
    },
    getHonor() {
      getHonor().then(res => {
        if (res && res.code === 200) {
          this.honorList = []
          for (var i = 0; i < res.data.length; i++) {
            this.srcList.push(pictureBaseUrl + res.data[i].fileId)
            if (i === 0 || i % 4 === 0) {
              const honor = []
              honor.push({
                imgUrl: res.data[i].fileId,
                name: res.data[i].name
              })
              this.honorList.push(honor)
            } else {
              this.honorList[parseInt(i / 4)].push({
                imgUrl: res.data[i].fileId,
                name: res.data[i].name
              })
            }
          }
        }
      })
    },
    // 上一年
    handleLastYear() {
      if (this.developStep === 0) {
        this.developStep = 0
      } else {
        this.developStep--
      }
    },
    // 下一年
    handleNextYear() {
      if (this.developStep === this.developList.length - 1) {
        this.developStep = this.developList.length - 1
      } else {
        this.developStep++
      }
    }
  }
}
</script>

<style scoped>

</style>
