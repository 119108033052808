<template>
  <div class="zw-case zw-newsconsul">
    <!--面包屑导航-->
    <div style="padding: 20px 6%">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/caseCenter' }">案例中心</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--大图-->
    <!--    <div class="zw-case-bgImg" :style="{backgroundImage:'url('+ item.imgUrl +')'}">-->
    <!--      <div class="zw-case-bgImg-content">-->
    <!--        <p>精选客户案例</p>-->
    <!--        <span>分享数字化转型与升级过程中的成功经验</span>-->
    <!--        <div class="zw-case-buttom">-->
    <!--          精彩案例集下载-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <br>
    <!--内容-->
    <div class="zw-newsconsul-context">
      <ul class="zw-dynamic-list">
        <li class="zw-dynamic-list-noBorder" style="text-align: center;display: block">
          <h1 style="font-weight: bold">{{ item.title }}</h1>
        </li>
        <br>
        <li class="zw-dynamic-item">
          时间：{{ item.updateTime }}  &nbsp;&nbsp;浏览量：{{ item.viewCount }}次
        </li>
        <br>
        <li class="zw-dynamic-item">
          <span v-html="item.textContent" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getCaseView, getDictByDicKey, addViewCount } from '@/js/caseCenter'
import { getShowAdvert } from '@/js/carousel'

export default {
  name: 'CaseCenter',
  data() {
    return {
      picUrl: '',
      item: null,
      dataTotal: 0,
      caseTypeList: [],
      caseTypeKey: [],
      caseTypeValue: [],
      queryData: {
        limit: 10,
        page: 1,
        title: ''
      },
      ceasCenterList: []
    }
  },
  created() {
    // this.getShowAdvert()
    this.item = this.$route.params
    if (this.item !== null && this.item.id !== undefined && this.item.id !== null) {
      this.addViewCount(this.item.id)
    } else {
      this.$router.push({
        path: '/caseCenter'
      })
    }
  },
  methods: {
    addViewCount(id) {
      addViewCount({ id: id }).then(res => {
      })
    },
    getShowAdvert() {
      getShowAdvert({ type: '4' }).then(res => {
        if (res && res.code === 200) {
          this.picUrl = res.data[0].url
        }
      })
    },
    getDataList() {
      getCaseView(this.queryData).then(res => {
        if (res && res.code === 200) {
          this.dataTotal = res.data.total
          for (const record of res.data.records) {
            this.ceasCenterList.push({
              name: this.caseTypeValue[this.caseTypeKey.indexOf(record.caseType)],
              imgUrl: 'data:image/Jpeg;base64,' + record.coverFileId,
              title: record.customerName,
              remarks: record.introduction,
              tabs: record.keyWords.split('，')
            })
          }
        }
      })
    },
    getCaseTypeList() {
      this.caseTypeList = []
      getDictByDicKey({ dicKey: 'case' }).then(res => {
        if (res && res.code === 200) {
          for (const datum of res.data.data) {
            this.caseTypeList.push({
              key: datum.name,
              value: datum.key
            })
            this.caseTypeValue.push(datum.key)
            this.caseTypeKey.push(datum.name)
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
