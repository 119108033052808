<template>
  <vue-seamless-scroll
    :data="listData"
    :class-option="classOption"
    class="warp"
  >
    <ul v-for="(item, index) in dataList" :key="index" class="ul-item">
      <li v-for="(item1, index1) in item" :key="index1" class="li-item">
        <img :src="item1.showFileId" style="margin-top: -5px" height="52" width="200" alt="">
      </li>
    </ul>
    <!--    <ul v-if="secShow" class="ul-item">
      <li v-for="(item, index) in oursCustomerList2" :key="index" class="li-item">
        <img :src="item.imgUrl" height="52" width="200" alt="">
      </li>
    </ul>-->
  </vue-seamless-scroll>
</template>

<script>

export default {
  name: 'Example09Basic',
  props: {
    dataList: {
      type: [Array, Object],
      default() {
        return []
      }
    }
  },
  data() {
    return {
      secShow: false,
      oursCustomerList2: [],
      oursCustomerList: [],
      listData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      classOption: {
        limitMoveNum: 5,
        direction: 2
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.warp {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    &.ul-item {
      display: flex;
      .li-item {
        background-color: #ffffff;
        width: 240px;
        height: 100px;
        margin-right: 10px;
        border-radius: 7px;
        // border: 1px solid #000;
      }
    }
  }
}
</style>
