import request from '@/utils/request'

/**
 * 下载附件
 * @param fileId
 * @returns
 */
export function downLoadFile(fileId) {
  return request({
    url: `/sysFile/download`,
    method: 'get',
    params: {
      fileId: fileId
    },
    responseType: 'blob'
  })
}
