<template>
  <div class="zw-product-four-template">
    <!--    背景图-->
    <div
      :style="{backgroundImage:'url('+ bgImgUrl +')'}"
      class="zw-four-bg"
    >
      <div class="zw-four-bg-tile">
        <div>工资查询</div>
        <span>便捷工资查询，实时高效</span>
      </div>
    </div>
    <div class="zw-four-bg-introduc">
      <div
        style="display: flex;justify-content: center;align-items: center;
        margin-bottom: 20px"
      >
        <img src="../../assets/image/product/fourLeft.png" alt="">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
         padding: 0 20px"
        >系统简介</div>
        <img src="../../assets/image/product/fourRight.png" alt="">
      </div>
      <div
        style="display: flex;padding: 50px 0 0;width: 100%;
      justify-content: center;flex-direction: column"
      >
        <div
          style="font-size: 16px;color: #000000;
        line-height: 26px"
        >
          该系统主要用于工资查询，包含人员信息的维护和工资数据的同步。产品配合人员台账系统，可做到及时、
          准确查询和掌握个人工资情况、个人历史工资及当月工资发放情况。提供两种查询途径，一是通过电脑用网
          页查询，二是通过手机用公众号和小程序查。
        </div>
        <el-divider />
      </div>
    </div>
    <div class="zw-four-bg-trait">
      <div
        style="display: flex;justify-content: center;align-items: center;
        margin-bottom: 20px"
      >
        <img src="../../assets/image/product/fourLeft.png" alt="">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
          padding: 0 20px"
        >系统特点</div>
        <img src="../../assets/image/product/fourRight.png" alt="">
      </div>
      <div class="zw-trait-context">
        <ul>
          <li v-for="(item, index) in traitList" :key="index">
            <el-card style="width: 100%" shadow="always">
              <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
                <div style="text-align: center;padding-right: 20px;">
                  {{ item.text }}
                </div>
                <img :src="item.imgUrl" width="100px" height="100px" alt="">
              </div>

            </el-card>
          </li>
        </ul>
      </div>
    </div>
    <!--免费获取方案-->
    <div
      :style="{backgroundImage:'url('+ fontImg +')'}"
      class="zw-four-footer"
    >
      <div
        style="display: flex;align-items: center;height: 100%;
      justify-content: center;flex-direction: column"
      >
        <div style="font-size: 28px;font-weight: bold;color: #000000;padding-bottom: 30px">
          即刻为你的企业定制解决方案
        </div>
        <el-button size="small" type="primary" @click="handleProgramme">免费获取方案</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WageInquiry',
  data() {
    return {
      bgImgUrl: require('../../assets/image/product/fourBg.jpg'),
      fontImg: require('../../assets/image/product/fourfoot.jpg'),
      traitList: [
        {
          imgUrl: require('../../assets/image/product/four01.png'),
          text: '网络化多途径部署，安装灵活且兼顾地方特色；'
        },
        {
          imgUrl: require('../../assets/image/product/four02.png'),
          text: '适应于各级工资查询，实现了工资查询与管理的科学、准确、高效；'
        },
        {
          imgUrl: require('../../assets/image/product/four03.png'),
          text: '简化发放工资条流程，节省了发放工资条时间，同时提高了工作效率；'
        },
        {
          imgUrl: require('../../assets/image/product/four04.png'),
          text: '支持动态密码登录及找回，保证了数据安全；'
        }
      ]
    }
  },
  methods: {
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
