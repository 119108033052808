<template>
  <div class="zw-product-three-template">
    <!--    背景图-->
    <div
      :style="{backgroundImage:'url('+ bgImgUrl +')'}"
      class="zw-three-bg"
    >
      <div class="zw-three-bg-tile">
        <div>陕西财政系统自治与数据治理项目</div>
        <span>规范化数据标准，挖掘财政数据价值</span>
      </div>
    </div>
    <div class="zw-three-bg-introduc">
      <div
        style="display: flex;justify-content: center;flex-direction: column;
        align-items: center;padding: 0 20px"
      >
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
         padding: 0 20px"
        >系统简介</div>
        <el-divider />
      </div>
      <div
        style="display: flex;padding: 50px 0 0;width: 100%;
      justify-content: center;flex-direction: column"
      >
        <div
          style="font-size: 16px;color: #000000;
        line-height: 26px"
        >
          依托数据治理实现业务治理，支撑建立现代财政制度、发挥财政在国家治理中的基础和重要支柱作用。
          通过信息资源规划，梳理财政业务数据，建设包括贴源库、基础库、主题库在内的三层财政数据资源库。
          以及全域财政数据资源目录体系。多元化的归集数据、高标准的规范化数据，全方位的应用数据，
          以达到充分发挥财政数据价值的目的。
        </div>
      </div>
    </div>
    <div class="zw-three-bg-trait">
      <el-card shadow="always">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
          text-align: center;padding: 20px 0"
        >系统特点</div>
        <div class="zw-trait-context">
          <ul>
            <li v-for="(item, index) in traitList" :key="index">
              <img v-if="index!==0" src="../../assets/image/product/fgf.png" alt="">
              <div
                style="display: flex;flex-direction: column;padding: 25px 40px;
                align-items: center;color: #4c5057;height: 100%;justify-content: space-around"
              >
                <img :src="item.imgUrl" height="96px" alt="">
                <div style="text-align: center;padding-top: 30px;color: #000000">
                  {{ item.text }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </el-card>
    </div>
    <!--免费获取方案-->
    <div
      :style="{backgroundImage:'url('+ fontImg +')'}"
      class="zw-three-footer"
    >
      <div
        style="display: flex;align-items: center;height: 100%;
      justify-content: center;flex-direction: column"
      >
        <div style="font-size: 28px;font-weight: bold;color: #000000;padding-bottom: 30px">
          即刻为你的企业定制解决方案
        </div>
        <el-button class="depth" size="small" type="danger" @click="handleProgramme">免费获取方案</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Governance',
  data() {
    return {
      bgImgUrl: require('../../assets/image/product/threeBg.jpg'),
      fontImg: require('../../assets/image/product/threeFoot.jpg'),
      traitList: [
        {
          imgUrl: require('../../assets/image/product/three01.png'),
          text: '科学、简明、实用的信息资源规划理论作为顶层指导，对财政业务和数据进行全面梳理和分析，' +
              '形成建财政数据和业务的相关标准规范体系；'
        },
        {
          imgUrl: require('../../assets/image/product/three02.png'),
          text: '指导财政数据治理和信息化建设的科学有效实施，为财政数据资产的盘点、理清以及规范化处理提供基础支撑；'
        },
        {
          imgUrl: require('../../assets/image/product/three03.png'),
          text: '让数据信息更加准确、一致、完整和安全，进而通过数据架构驱动财政管理架构的成熟，并降低日常 IT管理的成本。'
        }
      ]
    }
  },
  methods: {
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
