<template>
  <div class="zw-product-four-template">
    <!--    背景图-->
    <div
      :style="{backgroundImage:'url('+ bgImgUrl +')'}"
      class="zw-four-bg"
    >
      <div class="zw-four-bg-tile">
        <div>法检工资</div>
        <span>行政、事业单位的本级工资管理</span>
      </div>
    </div>
    <div class="zw-four-bg-introduc">
      <div
        style="display: flex;justify-content: center;align-items: center;
        margin-bottom: 20px"
      >
        <img src="../../assets/image/product/fourLeft.png" alt="">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
         padding: 0 20px"
        >系统简介</div>
        <img src="../../assets/image/product/fourRight.png" alt="">
      </div>
      <div
        style="display: flex;padding: 50px 0 0;width: 100%;
      justify-content: center;flex-direction: column"
      >
        <div
          style="font-size: 16px;color: #000000;
        line-height: 26px"
        >
          系统以国家人事部、财政部等有关政策、文件为依据，使工资管理规范；系统灵活方便又兼顾地方特色，
          适合行政、事业单位的本级工资管理工作。
        </div>
        <el-divider />
      </div>
    </div>
    <div class="zw-four-bg-trait">
      <div
        style="display: flex;justify-content: center;align-items: center;
        margin-bottom: 20px"
      >
        <img src="../../assets/image/product/fourLeft.png" alt="">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
          padding: 0 20px"
        >系统特点</div>
        <img src="../../assets/image/product/fourRight.png" alt="">
      </div>
      <div class="zw-trait-context">
        <ul>
          <li v-for="(item, index) in traitList" :key="index">
            <el-card style="width: 100%" shadow="always">
              <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
                <div style="text-align: center;padding-right: 20px;">
                  {{ item.text }}
                </div>
                <img :src="item.imgUrl" width="100px" height="100px" alt="">
              </div>
            </el-card>
          </li>
        </ul>
      </div>
    </div>
    <!--免费获取方案-->
    <div
      :style="{backgroundImage:'url('+ fontImg +')'}"
      class="zw-four-footer"
    >
      <div
        style="display: flex;align-items: center;height: 100%;
      justify-content: center;flex-direction: column"
      >
        <div style="font-size: 28px;font-weight: bold;color: #000000;padding-bottom: 30px">
          即刻为你的企业定制解决方案
        </div>
        <el-button size="small" type="primary" @click="handleProgramme">免费获取方案</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LegalInspection',
  data() {
    return {
      bgImgUrl: require('../../assets/image/product/fourBg.jpg'),
      fontImg: require('../../assets/image/product/fourfoot.jpg'),
      traitList: [
        {
          imgUrl: require('../../assets/image/product/four01.png'),
          text: '支持工资自发单位独立部署，灵活方便；'
        },
        {
          imgUrl: require('../../assets/image/product/four02.png'),
          text: '工资项公式提供自定义配置，自动套算工资；'
        },
        {
          imgUrl: require('../../assets/image/product/four03.png'),
          text: '支持人员批量维护；'
        },
        {
          imgUrl: require('../../assets/image/product/four04.png'),
          text: '支持工资批量调整及零星调整。'
        }
      ]
    }
  },
  methods: {
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
