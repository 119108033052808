import request from '@/utils/request'

// 基础信息列表
export function getCaseView(data) {
  return request({
    url: `/tbCase/getCaseView`,
    method: 'get',
    params: data
  })
}

export function getCaseMainShow(data) {
  return request({
    url: `/tbCase/getCaseMainShow`,
    method: 'get',
    params: data
  })
}

export function getDictByDicKey(data) {
  return request({
    url: 'sysDataDict/getDictByDicKey',
    method: 'get',
    params: data
  })
}

// 基础信息列表
export function addViewCount(data) {
  return request({
    url: `/tbCase/addViewCount`,
    method: 'get',
    params: data
  })
}
