<template>
  <div class="zw-newsconsul">
    <div style="padding: 20px 6%">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/newsConsul' }">新闻资讯</el-breadcrumb-item>
        <el-breadcrumb-item>详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--大图-->
    <!--    <div class="zw-newsconsul-bgImg" :style="{backgroundImage:'url('+ item.imgUrl +')'}">-->
    <!--      <div class="zw-newsconsul-bgImg-content">-->
    <!--        <p>新闻咨询</p>-->
    <!--        <span>分享数字化转型与升级过程中的成功经验</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <br>
    <div class="zw-newsconsul-context">
      <ul v-if="item.contentType !== '2'" class="zw-dynamic-list">
        <li class="zw-dynamic-list-noBorder" style="text-align: center;display: block">
          <h1 style="font-weight: bold">{{ item.title }}</h1>
        </li>
        <br>
        <li class="zw-dynamic-item">
          时间：{{ item.updateTime }}  &nbsp;&nbsp;浏览量：{{ item.viewCount }}次
        </li>
        <br>
        <li class="zw-dynamic-item">
          <span v-html="item.textContent" />
        </li>
      </ul>
      <iframe
        v-else
        id="mainiframe"
        :src="item.textContent"
        width="100%"
        height="1200px"
        allowtransparency="true"
        style="background-color:transparent"
      >
        您的浏览器不支持嵌入式框架，或者当前配置为不显示嵌入式框架。
      </iframe>
    </div>
  </div>
</template>

<script>
import { getNews, addViewCount, getContentById } from '@/js/newsConsul'
import { downLoadFile } from '@/js/upload'
import { getShowAdvert } from '@/js/carousel'

export default {
  name: 'NewsConsul',
  data() {
    return {
      picUrl: '',
      queryData: {
        limit: 10,
        page: 1,
        title: '',
        type: '1'
      },
      dataTotal: 0,
      activeName: '1',
      currentPageFirst: 1,
      currentPageThird: 1,
      whitePaperList: [],
      newsconsulList: [],
      item: null
    }
  },
  created() {
    // this.getShowAdvert()
    this.item = this.$route.params
    if (this.item !== null && this.item.id !== undefined && this.item.id !== null) {
      this.addViewCount(this.item.id)
      this.getContentById(this.item.id)
    } else {
      this.$router.push({
        path: '/newsConsul'
      })
    }
  },
  methods: {
    getContentById(id) {
      getContentById({ id: id }).then(res => {
        if (res && res.code === 200) {
          this.item.textContent = res.data.textContent
        }
      })
    },
    addViewCount(id) {
      addViewCount({ id: id }).then(res => {
      })
    },
    getShowAdvert() {
      getShowAdvert({ type: '5' }).then(res => {
        if (res && res.code === 200) {
          this.picUrl = res.data[0].url
        }
      })
    },
    getDataList() {
      getNews(this.queryData).then(res => {
        if (res && res.code === 200) {
          if (this.queryData.type === '1' || this.queryData.type === '2') {
            this.newsconsulList = []
            this.dataTotal = res.data.total
            for (const record of res.data.records) {
              let context = ''
              if (record.textContent !== null && record.textContent !== '') {
                if (record.textContent.length > 85) {
                  context = record.textContent.substr(0, 85) + '...'
                } else {
                  context = record.textContent
                }
              }
              this.newsconsulList.push({
                imgUrl: 'data:image/Jpeg;base64,' + record.fileId,
                title: record.title,
                timeData: this.dateFormat(record.createTime),
                context: context,
                browse: record.viewCount
              })
            }
          } else {
            this.whitePaperList = []
            this.dataTotal = res.data.total
            for (const record of res.data.records) {
              this.whitePaperList.push({
                imgUrl: 'data:image/Jpeg;base64,' + record.fileId,
                title: record.title.split('.')[0],
                fileName: record.fileName,
                textContent: record.textContent
              })
            }
          }
        }
      })
    },
    dateFormat(date) {
      var d = new Date(date)
      var d2 = d.getFullYear() + '年' + (d.getMonth() + 1) + '月' + d.getDate() + '日'
      return d2
    },
    tabClick(tab, event) {
      this.queryData.type = this.activeName
      this.getDataList()
    },
    downFile(fileId, title) {
      downLoadFile(fileId).then(res => {
        const blob = new Blob([res])
        const elink = document.createElement('a')
        elink.download = title
        elink.style.display = 'none'
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        URL.revokeObjectURL(elink.href)
        document.body.removeChild(elink)
      })
    }
  }
}
</script>

<style scoped>

</style>
