<template>
  <div class="zw-product-three-template">
    <!--    背景图-->
    <div
      :style="{backgroundImage:'url('+ bgImgUrl +')'}"
      class="zw-three-bg"
    >
      <div class="zw-three-bg-tile">
        <div>人脸核身</div>
        <span>人脸识别、人证核身服务</span>
      </div>
    </div>
    <div class="zw-three-bg-introduc">
      <div
        style="display: flex;justify-content: center;flex-direction: column;
        align-items: center;padding: 0 20px"
      >
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
         padding: 0 20px"
        >系统简介</div>
        <el-divider />
      </div>
      <div
        style="display: flex;padding: 50px 0 0;width: 100%;
      justify-content: center;flex-direction: column"
      >
        <div
          style="font-size: 16px;color: #000000;
        line-height: 26px"
        >
          运用现代信息技术采用人脸识别的方式，通过小程序录入姓名、身份证然后调用人脸核身的
          接口进行人员活体认证。调用人脸核身活体检测接口，用户通过视频拍摄过程中读取数字，
          来进行活体检测的判断。最大限度方便退休人员，真正实现足不出户就能完成年审。
        </div>
      </div>
    </div>
    <div class="zw-three-bg-trait">
      <el-card shadow="always">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
          text-align: center;padding: 20px 0"
        >系统特点</div>
        <div class="zw-trait-context">
          <ul>
            <li v-for="(item, index) in traitList" :key="index">
              <img v-if="index!==0" src="../../assets/image/product/fgf.png" alt="">
              <div
                style="display: flex;flex-direction: column;padding: 25px 40px;
                align-items: center;color: #4c5057;height: 100%;justify-content: space-around"
              >
                <img :src="item.imgUrl" height="96px" alt="">
                <div style="text-align: center;padding-top: 30px;color: #000000">
                  {{ item.text }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </el-card>
    </div>
    <!--免费获取方案-->
    <div
      :style="{backgroundImage:'url('+ fontImg +')'}"
      class="zw-three-footer"
    >
      <div
        style="display: flex;align-items: center;height: 100%;
      justify-content: center;flex-direction: column"
      >
        <div style="font-size: 28px;font-weight: bold;color: #000000;padding-bottom: 30px">
          即刻为你的企业定制解决方案
        </div>
        <el-button class="depth" size="small" type="danger" @click="handleProgramme">免费获取方案</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HumanNuclear',
  data() {
    return {
      bgImgUrl: require('../../assets/image/product/threeBg.jpg'),
      fontImg: require('../../assets/image/product/threeFoot.jpg'),
      traitList: [
        {
          imgUrl: require('../../assets/image/product/three01.png'),
          text: '无需安装任何软件，通过微信小程序即可访问；'
        },
        {
          imgUrl: require('../../assets/image/product/three02.png'),
          text: '方便、快捷地进行退休人员年检认证，为财政节省人力、物力和财力；'
        },
        {
          imgUrl: require('../../assets/image/product/three03.png'),
          text: '无区域限制，支持人员流动需要。'
        }
      ]
    }
  },
  methods: {
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
