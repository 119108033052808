import request from '@/utils/request'

// 基础信息列表
export function getCustomerMarkShow(data) {
  return request({
    url: `/tbCustomerMark/getCustomerMarkShow`,
    method: 'get',
    params: data
  })
}
