<template>
  <div>
    <div style="position: fixed;bottom: 120px;right: 17px;z-index: 100">
      <el-popover
        placement="left"
        trigger="hover"
      >
        <div class="zw-wechat-fixed">
          <div>
            扫码立即咨询客服
          </div>
          <div style="width: 150px;text-align: center">
            <img src="../assets/img/qqxz.png" width="100%" alt="">
          </div>
        </div>
        <div
          slot="reference"
          class="reference"
          style="border-top-right-radius: 5px;
  border-top-left-radius: 5px;"
        >
          <img src="../assets/img/zxzx.png" width="30" alt="">
          <!--            <a-icon :style="{ fontSize: '28px' }" type="wechat" />-->
          <span style="font-size: 14px">在线咨询</span>
        </div>
      </el-popover>
      <el-popover
        placement="left"
        trigger="hover"
      >
        <div class="zw-wechat-fixed">
          <div>
            联系电话
            <span
              style="font-size: 18px;font-weight: bold;
              display: block;color: #C7000B"
            >
              029-89181990
            </span>
          </div>
        </div>
        <div slot="reference" class="reference">
          <img src="../assets/img/dhgt.png" width="30" alt="">
          <!--            <i :style="{ fontSize: '28px',textAlign: 'center' }" class="el-icon-phone-outline" />-->
          <span style="font-size: 14px">电话沟通</span>
        </div>
      </el-popover>
      <el-popover
        placement="left"
        trigger="hover"
      >
        <div class="zw-wechat-fixed">
          <div>
            提交您的需求，专家与您联系
          </div>
        </div>
        <div
          slot="reference"
          class="reference"
          style="border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;cursor: pointer"
          @click="handleGetScheme"
        >
          <span class="getScheme">获取方案</span>
        </div>
      </el-popover>
    </div>
    <el-backtop style="width: 66px" :visibility-height="0" target=".el-main" :bottom="80" :right="17">
      <div class="reference" style="border-radius: 5px;width: 100%;padding: 6px 5px">
        <a-icon :style="{ fontSize: '28px' }" type="vertical-align-top" />
      </div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: 'FixedPosition',
  data() {
    return {

    }
  },
  methods: {
    handleGetScheme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
