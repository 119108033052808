<template>
  <div class="zw-product-five-template">
    <!--    背景图-->
    <div
      :style="{backgroundImage:'url('+ bgImgUrl +')'}"
      class="zw-five-bg"
    >
      <div class="zw-five-bg-tile">
        <div>人员台账</div>
        <span>财政人员工资统发一体化管理</span>
      </div>
    </div>
    <div class="zw-five-bg-introduc">
      <div
        style="display: flex;justify-content: center;
      flex-direction: column;align-items: center;margin-bottom: 20px"
      >
        <img src="../../assets/image/product/xtjs.png" alt="">
        <div
          style="font-size: 28px;margin-top: -20px;
        font-weight: bold;color: #000000;"
        >系统简介</div>
        <span style="border: 3px solid #00c7c5;width: 80px;margin-top: 20px" />
      </div>
      <div style="display: flex;padding: 50px 0;width: 100%;justify-content: center">
        <div style="">
          <img src="../../assets/image/product/xtwj.png" width="450" alt="">
        </div>
        <div
          style="display: flex;flex-direction: column;
        justify-content: space-between;width: 40%;min-width: 450px; "
        >
          <div
            style="font-size: 16px;color: #000000;
        line-height: 26px"
          >
            系统用于预算单位各类人员的人事工资数据采集，进行人员数据汇总，满足财政人员信息化统计需求，
            建立人员台账数据中心。加强财政对各单位人员工资的管理，实现人员工资标准化、规范化管理，
            建立全面的人员和工资信息管理系统。为预算单位提供了工资台账的管理平台，为财政部门提供了数据支撑，
            为预算管理（人员经费）提供了数据支撑，满足了财政一体化业务中人员工资发放纳入一体化管理的需要。
          </div>
          <div style="display: flex;justify-content: right;width: 100%">
            <el-divider />
            <div style="border: 3px solid #00c7c5;width: 80px;margin-top: -2px" />
          </div>
        </div>
      </div>
    </div>
    <div class="zw-five-bg-trait">
      <div
        style="display: flex;justify-content: center;
      flex-direction: column;align-items: center;margin-bottom: 20px"
      >
        <img src="../../assets/image/product/xtjs.png" alt="">
        <div
          style="font-size: 28px;margin-top: -20px;
        font-weight: bold;color: #000000;"
        >系统特点</div>
        <span style="border: 3px solid #00c7c5;width: 80px;margin-top: 20px" />
      </div>
      <div class="zw-trait-context">
        <ul>
          <li v-for="(item, index) in traitList" :key="index">
            <div>
              <img :src="item.imgUrl" alt="">
            </div>
            <div style="text-align: center;color: #000000">
              {{ item.text }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--免费获取方案-->
    <div
      :style="{backgroundImage:'url('+ fontImg +')'}"
      class="zw-five-footer"
    >
      <div
        style="display: flex;align-items: center;height: 100%;
      justify-content: center;flex-direction: column"
      >
        <div style="font-size: 28px;font-weight: bold;color: #000000;padding-bottom: 30px">
          即刻为你的企业定制解决方案
        </div>
        <el-button size="small" type="primary" @click="handleProgramme">免费获取方案</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonnelLedger',
  data() {
    return {
      bgImgUrl: require('../../assets/image/product/fiveTemBg.jpg'),
      fontImg: require('../../assets/image/product/mfhqfa.jpg'),
      traitList: [
        {
          imgUrl: require('../../assets/image/product/five01.png'),
          text: '工资标准可配置，提供最新的标准套算工资，减少人为干预，提高工作效率；'
        },
        {
          imgUrl: require('../../assets/image/product/five02.png'),
          text: '由财政统一归档，彻底解决单位上报月份有误的问题；'
        },
        {
          imgUrl: require('../../assets/image/product/five03.png'),
          text: '数据集中存贮、管理，保证数据的安全性，为领导决策提供依据；'
        },
        {
          imgUrl: require('../../assets/image/product/five04.png'),
          text: '管理对象多元化，支持统发、非统发人员管理；'
        },
        {
          imgUrl: require('../../assets/image/product/five05.png'),
          text: '按照归口科室数据管理、按照发放对象数据管理、按照权限划分不同的功能角色等。'
        }
      ]
    }
  },
  methods: {
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
