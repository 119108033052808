<template>
  <!--顶部导航-->
  <div class="zw-header">
    <div class="zw-nav">
      <div class="zw-nav-left">
        <div style="height: 38px">
          <img src="../assets/img/logo1.png" height="100%" alt="">
        </div>
      </div>
      <div class="zw-nav-center">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          text-color="#252b3a"
          active-text-color="#C7000B"
          @select="handleSelect"
        >
          <el-menu-item index="1">
            首页
          </el-menu-item>
          <el-menu-item index="2">
            <el-popover
              placement="bottom"
              popper-class="headerPopover"
              trigger="hover"
            >
              <div style="width: 100%;">
                <product />
              </div>
              <div slot="reference">产品</div>
            </el-popover>
          </el-menu-item>
          <!--          <el-menu-item index="3">-->
          <!--            <el-popover-->
          <!--              placement="bottom"-->
          <!--              popper-class="headerPopover"-->
          <!--              trigger="hover"-->
          <!--            >-->
          <!--              <div style="width: 100%;">-->
          <!--                <solution />-->
          <!--              </div>-->
          <!--              <div slot="reference">解决方案</div>-->
          <!--            </el-popover>-->
          <!--          </el-menu-item>-->
          <el-menu-item index="4">
            案例中心
          </el-menu-item>
          <el-menu-item index="5">
            新闻资讯
          </el-menu-item>
          <el-submenu index="6">
            <template slot="title">关于我们</template>
            <el-menu-item index="6-1">
              <div style="display: flex;align-items: center;padding: 0 20px">
                <span
                  style="display: inline-block;width: 5px;height: 5px;
                border-radius: 50%;background-color: #333333;margin-right: 5px;"
                />
                <p>企业介绍</p>
              </div>
            </el-menu-item>
            <el-menu-item index="6-2">
              <div style="display: flex;align-items: center;padding: 0 20px">
                <span
                  style="display: inline-block;width: 5px;height: 5px;
                border-radius: 50%;background-color: #333333;margin-right: 5px;"
                />
                <p>招聘专栏</p>
              </div>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div class="zw-nav-right">
        <div style="display: flex;align-items: center">
          <img src="../assets/img/cdldh.png" width="26" alt="">
          <!--          <i class="el-icon-phone-outline" style="font-size: 20px;font-weight: bold" />-->
          <span style="padding-right: 15px">029-89181990</span>
        </div>
        <el-button size="mini" type="danger" @click="handleGetScheme">获取方案</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import product from './product' // 产品
// import solution from './solution' // 解决方案

export default {
  name: 'NavigationCom',
  components: {
    product
  },
  data() {
    return {
      activeIndex: '1'
    }
  },
  watch: {
    '$route'(to, from) {
      if (this.$route.query.active !== undefined) {
        this.activeIndex = this.$route.query.active
      }
    }
  },
  methods: {
    // 获取方案
    handleGetScheme() {
      this.$router.push({
        path: '/getScheme'
      })
    },
    handleSelect(val) {
      switch (val) {
        case '1':
          this.$router.push({
            path: '/homePage'
          })
          break
        case '2':
          // this.$router.push({
          //   path: '/tailorism'
          // })
          break
        case '3':
          // this.$router.push({
          //   path: '/solution'
          // })
          break
        case '4':
          this.$router.push({
            path: '/caseCenter'
          })
          break
        case '5':
          this.$router.push({
            path: '/newsConsul'
          })
          break
        case '6-1':
          this.$router.push({
            path: '/aboutUs'
          })
          break
        case '6-2':
          this.$router.push({
            path: 'recruit'
          })
          break
        default:
          this.$router.push({
            path: '/homePage'
          })
      }
    }
  }
}
</script>
<style>
  .headerPopover{
    width: 100%;
    left: 0 !important;
    margin-top: 1px !important;
    padding: 0 15px !important;
  }
  .headerPopover[x-placement^="bottom"] .popper__arrow::after {
    display: none;
  }
  .headerPopover[x-placement^="bottom"] .popper__arrow {
    display: none;
  }
</style>
