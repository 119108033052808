<template>
  <div class="zw-product-three-template">
    <!--    背景图-->
    <div
      :style="{backgroundImage:'url('+ bgImgUrl +')'}"
      class="zw-three-bg"
    >
      <div class="zw-three-bg-tile">
        <div>一库一站一平台</div>
        <span>推动高端会计人才的培养</span>
      </div>
    </div>
    <div class="zw-three-bg-introduc">
      <div
        style="display: flex;justify-content: center;flex-direction: column;
        align-items: center;padding: 0 20px"
      >
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
         padding: 0 20px"
        >系统简介</div>
        <el-divider />
      </div>
      <div
        style="display: flex;padding: 50px 0 0;width: 100%;
      justify-content: center;flex-direction: column"
      >
        <div
          style="font-size: 16px;color: #000000;
        line-height: 26px"
        >
          为切实增强会计管理机构服务质量和工作水平，培养更多高层次会计人才，服务经济社会高质量发展，建立的会计监管服务体系，
          包含会计人员信息库、经济专家工作站、会计人员服务平台。转变会计人员管理方式，加强会计人员事中事后管理，切实推动会计
          工作的创新发展、会计管理工作数字化转型，加强会计人才培养基地建设，推动高端会计人才的培养。
        </div>
      </div>
    </div>
    <div class="zw-three-bg-trait">
      <el-card shadow="always">
        <div
          style="font-size: 28px;font-weight: bold;color: #000000;
          text-align: center;padding: 20px 0"
        >系统特点</div>
        <div class="zw-trait-context">
          <ul>
            <li v-for="(item, index) in traitList" :key="index">
              <img v-if="index!==0" src="../../assets/image/product/fgf.png" alt="">
              <div
                style="display: flex;flex-direction: column;padding: 25px 40px;
                align-items: center;color: #4c5057;height: 100%;justify-content: space-around"
              >
                <img :src="item.imgUrl" height="96px" alt="">
                <div style="text-align: center;padding-top: 30px;color: #000000">
                  {{ item.text }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </el-card>
    </div>
    <!--免费获取方案-->
    <div
      :style="{backgroundImage:'url('+ fontImg +')'}"
      class="zw-three-footer"
    >
      <div
        style="display: flex;align-items: center;height: 100%;
      justify-content: center;flex-direction: column"
      >
        <div style="font-size: 28px;font-weight: bold;color: #000000;padding-bottom: 30px">
          即刻为你的企业定制解决方案
        </div>
        <el-button class="depth" size="small" type="danger" @click="handleProgramme">免费获取方案</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StationLibrary',
  data() {
    return {
      bgImgUrl: require('../../assets/image/product/threeBg.jpg'),
      fontImg: require('../../assets/image/product/threeFoot.jpg'),
      traitList: [
        {
          imgUrl: require('../../assets/image/product/three01.png'),
          text: '通过公众号即可访问系统，方便会计人员随时随地查看招聘相关信息；'
        },
        {
          imgUrl: require('../../assets/image/product/three02.png'),
          text: '配置数据驾驶舱，方便使用者直观掌握系统情况，可通过分析、比较做出科学精准的决策和判断；'
        },
        {
          imgUrl: require('../../assets/image/product/three03.png'),
          text: '人员注册、信息变更等均需要审核，在解决求职和招聘的同时保障了信息安全。'
        }
      ]
    }
  },
  methods: {
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
