<template>
  <div class="zw-product-five-template">
    <!--    背景图-->
    <div
      :style="{backgroundImage:'url('+ bgImgUrl +')'}"
      class="zw-five-bg"
    >
      <div class="zw-five-bg-tile">
        <div>人力资源综合管理平台</div>
        <span>提供机关事业单位综合管理服务平台</span>
      </div>
    </div>
    <div class="zw-five-bg-introduc">
      <div
        style="display: flex;justify-content: center;
      flex-direction: column;align-items: center;margin-bottom: 20px"
      >
        <img src="../../assets/image/product/xtjs.png" alt="">
        <div
          style="font-size: 28px;margin-top: -20px;
        font-weight: bold;color: #000000;"
        >系统简介</div>
        <span style="border: 3px solid #00c7c5;width: 80px;margin-top: 20px" />
      </div>
      <div style="display: flex;padding: 50px 0;width: 100%;justify-content: center">
        <div style="">
          <img src="../../assets/image/product/xtwj.png" width="450" alt="">
        </div>
        <div
          style="display: flex;flex-direction: column;
        justify-content: space-between;width: 40%;min-width: 450px; "
        >
          <div
            style="font-size: 16px;color: #000000;
        line-height: 26px"
          >
            系统包含机构编制部门管理信息、组织、人社部门管理信息、财政工资统发管理信息、预算单位人事、
            工资管理信息五个核心子系统及领导查询、政务公开、内部管理政务公开、微信工资查询、代发银行五
            个子系统。
          </div>
          <div style="display: flex;justify-content: right;width: 100%">
            <el-divider />
            <div style="border: 3px solid #00c7c5;width: 80px;margin-top: -2px" />
          </div>
        </div>
      </div>
    </div>
    <div class="zw-five-bg-trait">
      <div
        style="display: flex;justify-content: center;
      flex-direction: column;align-items: center;margin-bottom: 20px"
      >
        <img src="../../assets/image/product/xtjs.png" alt="">
        <div
          style="font-size: 28px;margin-top: -20px;
        font-weight: bold;color: #000000;"
        >系统特点</div>
        <span style="border: 3px solid #00c7c5;width: 80px;margin-top: 20px" />
      </div>
      <div class="zw-trait-context">
        <ul>
          <li v-for="(item, index) in traitList" :key="index">
            <div>
              <img :src="item.imgUrl" alt="">
            </div>
            <div style="text-align: center;color: #000000">
              {{ item.text }}
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!--免费获取方案-->
    <div
      :style="{backgroundImage:'url('+ fontImg +')'}"
      class="zw-five-footer"
    >
      <div
        style="display: flex;align-items: center;height: 100%;
      justify-content: center;flex-direction: column"
      >
        <div style="font-size: 28px;font-weight: bold;color: #000000;padding-bottom: 30px">
          即刻为你的企业定制解决方案
        </div>
        <el-button size="small" type="primary" @click="handleProgramme">免费获取方案</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HumanResources',
  data() {
    return {
      bgImgUrl: require('../../assets/image/product/fiveTemBg.jpg'),
      fontImg: require('../../assets/image/product/mfhqfa.jpg'),
      traitList: [
        {
          imgUrl: require('../../assets/image/product/five01.png'),
          text: '设计VPN、设备沙盒、专网登录，系统安全性高、信息保密性强；'
        },
        {
          imgUrl: require('../../assets/image/product/five02.png'),
          text: '界面简洁、功能齐全、操作方便；'
        },
        {
          imgUrl: require('../../assets/image/product/five03.png'),
          text: '对工资项的计算公式提供自定义配置，自动套算工资；'
        },
        {
          imgUrl: require('../../assets/image/product/five04.png'),
          text: '人性化设定操作权限管理和保密措施；'
        },
        {
          imgUrl: require('../../assets/image/product/five05.png'),
          text: '批量数据更改功能；'
        },
        {
          imgUrl: require('../../assets/image/product/five05.png'),
          text: '自定义报表，提供决策依据。'
        }
      ]
    }
  },
  methods: {
    // 免费获取方案
    handleProgramme() {
      this.$router.push({
        path: '/getScheme'
      })
    }
  }
}
</script>

<style scoped>

</style>
