<template>
  <div class="zw-delivery">
    <!--投递简历-->
    <div v-if="deliveryShow" class="zw-delivery-content">
      <!--导航-->
      <div style="padding: 20px 0">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            :to="{path: '/recruit',query: {
              active: '6-2'
            }

            }"
          >招聘专栏</el-breadcrumb-item>
          <el-breadcrumb-item
            :to="{ path: '/jobDetails',query: {
              rowJobObj: rowDeliveryObj
            } }"
          >职位详情</el-breadcrumb-item>
          <el-breadcrumb-item>职位投递</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="zw-delivery-substance">
        <div class="zw-substance-top">
          <p style="font-size: 16px">鑫众为软件</p>
          <p style="font-size: 26px;color: #333333;padding: 10px 0">{{ rowDeliveryObj.position }}</p>
          <div style="display: flex;align-items: center;justify-content: space-between">
            <div>
              <span>{{ rowDeliveryObj.positionType }}</span>
              <el-divider direction="vertical" />
              <span>工作地点： {{ rowDeliveryObj.workAddress }}</span>
              <el-divider direction="vertical" />
              <span>招聘{{ rowDeliveryObj.count }}人</span>
            </div>
            <div>
              <span>发布时间：{{ rowDeliveryObj.releaseTime }}</span>
            </div>
          </div>
        </div>
        <div class="zw-substance-from">
          <ul class="zw-substance-left">
            <el-form
              ref="ruleForm"
              :model="ruleForm"
              :rules="rules"
              label-position="top"
            >
              <li id="deliverScjl">
                <div class="zw-from-title">
                  <p style="border-bottom: 1px solid #E4E4E4">上传简历</p>
                </div>
                <div style="padding: 20px 0">
                  <el-form-item label="" prop="resumeId">
                    <el-upload
                      class="upload-demo"
                      drag
                      :action="uploadUrl"
                      :limit="1"
                      :on-exceed="exceed"
                      :on-success="uploadSuccess"
                    >
                      <i class="el-icon-upload" />
                      <div class="el-upload__text">将文件拖到此处，
                        或<em style="color: #C7000B">点击上传</em>
                        <p>支持扩展名：ppt .pdf .png . jpg . doc . docx...</p>
                      </div>
                    </el-upload>
                  </el-form-item>
                </div>
              </li>
              <!--个人信息-->
              <li id="deliverGrjl">
                <div class="zw-from-title">
                  <p style="border-bottom: 1px solid #E4E4E4">个人信息</p>
                </div>
                <el-row>
                  <el-col :span="11">
                    <el-form-item label="姓名" prop="name">
                      <el-input v-model="ruleForm.name" placeholder="请输入姓名" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <div>
                      <el-form-item label="证件照" prop="certificateId">
                        <el-upload
                          :action="uploadUrl"
                          :class="{ hide: hideUploadIntroduce }"
                          list-type="picture-card"
                          :limit="1"
                          :on-change="handleUploadHide"
                          :on-remove="handleRemove"
                          :on-success="uploadCertificateSuccess"
                          :on-exceed="exceedCertificate"
                        >
                          <i class="el-icon-plus" />
                        </el-upload>
                      </el-form-item>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="11">
                    <el-form-item label="性别" prop="sex">
                      <el-select v-model="ruleForm.sex" style="width: 100%" placeholder="请选择性别">
                        <el-option label="男" value="1" />
                        <el-option label="女" value="2" />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <el-form-item label="手机号码" prop="phone">
                      <el-input v-model="ruleForm.phone" placeholder="请输入手机号码" />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="11">
                    <el-form-item label="邮箱" prop="email">
                      <el-input v-model="ruleForm.email" placeholder="请输入邮箱" />
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <el-form-item label="籍贯" prop="nativePlace">
                      <el-input v-model="ruleForm.nativePlace" placeholder="请输入籍贯" />
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="11">
                    <el-form-item label="出生日期" prop="birthDay">
                      <el-date-picker
                        v-model="ruleForm.birthDay"
                        type="date"
                        placeholder="请选择出生日期"
                        style="width: 100%;"
                      />
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <el-form-item label="工作状态" prop="workStatus">
                      <el-input v-model="ruleForm.workStatus" placeholder="请输入工作状态" />
                    </el-form-item>
                  </el-col>
                </el-row>
              </li>
              <!--教育背景-->
              <li id="deliverJybj">
                <div class="zw-from-title">
                  <p
                    style="border-bottom: 1px solid #E4E4E4;display: flex;
                                justify-content: space-between;align-items: center"
                  >
                    <span>教育背景</span>
                    <span
                      style="display: flex;align-items: center;
                                      color: #C7000B;cursor: pointer"
                      @click="handleEducaClick"
                    >
                      <i class="el-icon-plus" />
                      <span style="padding: 5px 10px">添加</span>
                    </span>
                  </p>
                </div>
                <div v-for="(item,index) in ruleForm.educaList" :key="index">
                  <p
                    v-if="index !== 0"
                    style="width: 100%;margin-bottom: 15px;
                                  border: none;border-bottom: 1px solid #E4E4E4"
                  />
                  <span
                    v-if="index !== 0"
                    style="display: flex;align-items: center;
                        color: #C7000B;cursor: pointer;margin-left: 93%"
                    @click="handleEducaMinusClick(index)"
                  >
                    <i class="el-icon-minus" />
                    <span style="padding: 5px 10px">删除</span>
                  </span>
                  <el-row>
                    <el-col :span="11">
                      <el-form-item
                        label="就读开始时间"
                        :rules="{ required: true, message: '请选择就读开始时间', trigger: 'change' }"
                        :prop="`educaList.${index}.studyStartTime`"
                      >
                        <el-date-picker
                          v-model="item.studyStartTime"
                          type="date"
                          placeholder="请选择开始时间"
                          style="width: 100%;"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item
                        label="就读结束时间"
                        :rules="{ required: true, message: '请选择就读结束时间', trigger: 'change' }"
                        :prop="`educaList.${index}.studyEndTime`"
                      >
                        <el-date-picker
                          v-model="item.studyEndTime"
                          type="date"
                          placeholder="请选择结束时间"
                          style="width: 100%;"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="11">
                      <el-form-item
                        label="学校名称"
                        :rules="{ required: true, message: '请输入学校名称', trigger: 'blur' }"
                        :prop="`educaList.${index}.schoolName`"
                      >
                        <el-input v-model="item.schoolName" placeholder="请输入学校名称" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item
                        label="专业名称"
                        :rules="{ required: true, message: '请输入专业名称', trigger: 'blur' }"
                        :prop="`educaList.${index}.majorName`"
                      >
                        <el-input v-model="item.majorName" placeholder="请输入专业名称" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="11">
                      <el-form-item
                        label="学历"
                        :rules="{ required: true, message: '请输入学历', trigger: 'blur' }"
                        :prop="`educaList.${index}.educationBackground`"
                      >
                        <el-input
                          v-model="item.educationBackground"
                          placeholder="请输入学历"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="11" />
                  </el-row>
                </div>
              </li>
              <!--工作经历-->
              <li id="deliverGzjl">
                <div class="zw-from-title">
                  <p
                    style="border-bottom: 1px solid #E4E4E4;display: flex;
                  justify-content: space-between;align-items: center"
                  >
                    <span>工作经历</span>
                    <span
                      style="display: flex;align-items: center;
                        color: #C7000B;cursor: pointer"
                      @click="handleWorkClick"
                    >
                      <i class="el-icon-plus" />
                      <span style="padding: 5px 10px">添加</span>
                    </span>
                  </p>
                </div>
                <div v-for="(item, index) in workList" :key="index">
                  <p
                    v-if="index !== 0"
                    style="width: 100%;margin-bottom: 15px;
                    border: none;border-bottom: 1px solid #E4E4E4"
                  />
                  <span
                    v-if="index !== 0"
                    style="display: flex;align-items: center;
                        color: #C7000B;cursor: pointer;margin-left: 93%"
                    @click="handleWorkMinusClick(index)"
                  >
                    <i class="el-icon-minus" />
                    <span style="padding: 5px 10px">删除</span>
                  </span>
                  <el-row>
                    <el-col :span="11">
                      <el-form-item label="开始时间">
                        <el-date-picker
                          v-model="workList[index].workStartTime"
                          type="date"
                          placeholder="请选择开始时间"
                          style="width: 100%;"
                        />
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item label="结束时间">
                        <el-date-picker
                          v-model="workList[index].workEndTime"
                          type="date"
                          placeholder="请选择结束时间"
                          style="width: 100%;"
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="11">
                      <el-form-item label="公司名称">
                        <el-input v-model="workList[index].companyName" placeholder="请输入公司名称" />
                      </el-form-item>
                    </el-col>
                    <el-col :span="11">
                      <el-form-item label="职位名称">
                        <el-input v-model="workList[index].positionName" placeholder="请输入职位名称" />
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="23">
                      <el-form-item label="工作职责">
                        <vxe-textarea
                          v-model="workList[index].workResponsibility"
                          placeholder="请输入工作职责"
                          style="height: 120px"
                          maxlength="200"
                          show-word-count
                        />
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </li>
            </el-form>
          </ul>
          <div class="zw-substance-right">
            <el-tabs
              v-model="activeName"
              style="position: fixed"
              tab-position="right"
              @tab-click="handleTabClick"
            >
              <el-tab-pane label="上传简历" name="deliverScjl" />
              <el-tab-pane label="个人信息" name="deliverGrjl" />
              <el-tab-pane label="教育背景" name="deliverJybj" />
              <el-tab-pane label="工作经历" name="deliverGzjl" />
            </el-tabs>
          </div>
        </div>
        <div style="text-align: center">
          <el-button
            style="padding: 12px 50px;font-size: 18px"
            class="depth"
            type="danger"
            plain
            @click="submit"
          >
            提交
          </el-button>
        </div>
      </div>
    </div>
    <!--投递简历成功-->
    <div v-if="!deliveryShow" class="zw-delivery-success">
      <div>
        <i class="el-icon-success" style="font-size: 78px;color: #C7000B" />
        <p>
          恭喜你，简历投递成功！请你静候佳音。
        </p>
        <div style="padding: 20px 0">
          <el-button class="depth" type="danger" plain size="mini">
            返回首页 （{{ count }}s后自动跳转）
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addDelivery } from '@/js/delivery'

export default {
  name: 'Delivery',
  data() {
    var validateEmail = (rule, value, callback) => {
      const regEmail = /^\w{1,64}@[a-z0-9\-]{1,256}(\.[a-z]{2,6}){1,2}$/i
      if (!regEmail.test(value)) {
        return callback(new Error('请输入合法的邮箱!'))
      }
      callback()
    }
    var validatePhone = (rule, value, callback) => {
      // 手机号一般最小以“13”开头
      const regMobile = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (!regMobile.test(value)) {
        // eslint-disable-next-line no-undef
        callback(new Error('请输入合法的手机号!'))
      }
      // eslint-disable-next-line no-undef
      callback()
    }
    return {
      deliveryShow: true,
      hideUploadIntroduce: false,
      count: 3,
      activeName: 'deliverScjl',
      ruleForm: {
        recruitId: '',
        certificateId: '',
        resumeId: '',
        birthDay: '',
        workStatus: '',
        nativePlace: '',
        email: '',
        phone: '',
        sex: '',
        name: '',
        educaList: [
          {
            studyStartTime: '',
            studyEndTime: '',
            schoolName: '',
            majorName: '',
            educationBackground: ''
          }
        ]
      },
      rules: {
        workStatus: [{ required: true, message: '请输入工作状态', trigger: 'blur' }],
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        nativePlace: [{ required: true, message: '请输入籍贯', trigger: 'blur' }],
        birthDay: [{ required: true, message: '请选择出生日期', trigger: 'change' }]
      },
      uploadUrl: process.env.VUE_APP_API_BASE_URL + '/sysFile/upload',
      workList: [
        {
          companyName: '',
          workStartTime: '',
          workEndTime: '',
          positionName: '',
          workResponsibility: ''
        }
      ],
      rowDeliveryObj: {}
    }
  },
  created() {
    this.rowDeliveryObj = this.$route.query.rowObj
    this.ruleForm.recruitId = this.rowDeliveryObj.id
  },
  methods: {
    handleUploadHide(file, fileList) {
      this.hideUploadIntroduce =
          fileList.length >= 1
    },
    handleRemove(file, fileList) {
      this.hideUploadIntroduce =
          fileList.length >= 1
    },
    uploadSuccess(response, file, fileList) {
      this.ruleForm.resumeId = response.data
    },
    exceed(files, fileList) {
      this.$message({
        type: 'warning',
        message: '简历文件只能上传一个!'
      })
    },
    uploadCertificateSuccess(response, file, fileList) {
      this.ruleForm.certificateId = response.data
    },
    exceedCertificate(files, fileList) {
      this.$message({
        type: 'warning',
        message: '证件照只能上传一个!'
      })
    },
    // 教育背景
    handleEducaClick() {
      this.ruleForm.educaList.push({
        studyStartTime: '',
        studyEndTime: '',
        schoolName: '',
        majorName: '',
        educationBackground: ''
      })
    },
    handleEducaMinusClick(index) {
      this.ruleForm.educaList.splice(index, 1)
    },
    handleWorkMinusClick(index) {
      this.workList.splice(index, 1)
    },
    // 添加工作经历
    handleWorkClick() {
      this.workList.push({
        companyName: '',
        workStartTime: '',
        workEndTime: '',
        positionName: '',
        workResponsibility: ''
      })
    },
    // 右侧的滚动
    handleTabClick(val) {
      document.getElementById(val.name).scrollIntoView({ block: 'center' })
    },
    submit() {
      this.$refs.ruleForm.validate((errMap) => {
        if (!errMap) {
          this.$message.warning('校验不通过！')
        } else {
          if (this.ruleForm.resumeId === '') {
            this.$message.warning('请上传简历！')
            return
          }
          if (this.ruleForm.certificateId === '') {
            this.$message.warning('请上传证件照！')
            return
          }
          const parm = {
            tbPositionDelivery: this.ruleForm,
            tbEducationBack: this.ruleForm.educaList,
            tbWorkExperience: this.workList
          }
          addDelivery(parm).then(res => {
            if (res && res.code === 200) {
              this.$message.success('提交成功！')
              this.deliveryShow = false
              this.count = 3
              var timeout = setInterval(() => {
                if (this.count === 0) {
                  clearInterval(timeout)
                  this.$router.push({
                    path: '/homePage',
                    query: {
                      active: '1'
                    }
                  })
                } else {
                  this.count--
                }
              }, 1000)
            }
          })
        }
      })
    }
  }
}
</script>

<style>
.hide .el-upload--picture-card {
  display: none;
}
/*去除upload组件过渡效果*/
.el-upload-list__item {
  transition: none !important;
}
</style>
