import Vue from 'vue'
import App from './App.vue'
import scroll from 'vue-seamless-scroll'

Vue.config.productionTip = false
// 路由
import router from './router/idnex'
import axios from 'axios'
Vue.prototype.$axios = axios

import '@/styles/index.scss'
// ant
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
Vue.use(Antd)
Vue.use(scroll)
// ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)
// vxe
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
// 公共组件
import carousel from '@/components/carousel' // 轮播图
Vue.component('carousel', carousel)
Vue.use(VXETable)
// 格式化
import VueHighlightJS from 'vue-highlight.js'
import 'highlight.js/styles/atom-one-dark.css'
Vue.use(VueHighlightJS)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
