import request from '@/utils/request'

/**
 * 下载附件
 * @param fileId
 * @returns
 */
export function addPlan(data) {
  return request({
    url: `/tbGetPlan/addPlan`,
    method: 'post',
    data
  })
}
