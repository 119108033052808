<template>
  <div class="zw-totalsolu">
    <!--轮播图-->
    <div class="zw-totalsolu-bgImg" :style="{backgroundImage:'url('+ pictureBaseUrl + picUrl +')'}">
      <!--      <div class="zw-aboutus-bgImg-content">-->
      <!--        <p style="color: #ffffff">关于鑫众为</p>-->
      <!--        <span>ABOUT US</span>-->
      <!--      </div>-->
    </div>
    <!--解决方案内容-->
    <div class="zw-totalsolu-context">
      <el-tabs v-model="activeName" :stretch="true">
        <el-tab-pane label="行业洞察" name="1">
          <div>
            <!--照片-->
            <div style="padding: 50px 0;text-align: center">
              <img src="../assets/img/csal.png" alt="">
            </div>
            <div class="scroll-item" style="padding: 10px 0;text-align: center" />
            <!--业务价值-->
            <div class="zw-totalsolu-business">
              <div class="zw-business-title">业务价值</div>
              <ul class="zw-business-context">
                <li v-for="(item,index) in businessList" :key="index">
                  <div class="zw-business-img">
                    <img :src="item.imgUrl" alt="">
                  </div>
                  <p>{{ item.title }}</p>
                  <span>{{ item.remarks }}</span>
                </li>
              </ul>
            </div>
            <!--应用场景-->
            <div class="zw-totalsolu-scene">
              <div class="zw-scene-title">应用场景</div>
              <div class="zw-scene-context">
                <div class="zw-scene-carousel">
                  <el-carousel :interval="-1" arrow="always" height="450px">
                    <el-carousel-item v-for="(item,index) in scenecarList" :key="index">
                      <div style="text-align: center">
                        <img :src="item.imgUrl" alt="">
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <ul class="zw-scene-list">
                  <li v-for="(item,index) in sceneList" :key="index">
                    <div class="zw-scene-img">
                      <img :src="item.imgUrl" alt="">
                    </div>
                    <p>{{ item.title }}</p>
                    <span>{{ item.remarks }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--客户案例-->
            <div class="zw-totalsolu-customer">
              <div class="zw-customer-title">客户案例</div>
              <ul class="zw-customer-list">
                <li v-for="(item,index) in customerList" :key="index">
                  <div>
                    <img :src="item.imgUrl" alt="">
                  </div>
                  <div class="zw-customer-right">
                    <p>{{ item.title }}</p>
                    <span>{{ item.content }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="解决方案" name="2">
          <div>
            <!--照片-->
            <div style="padding: 50px 0;text-align: center">
              <img src="../assets/img/csal.png" alt="">
            </div>
            <div class="scroll-item" style="padding: 10px 0;text-align: center" />
            <!--业务价值-->
            <div class="zw-totalsolu-business">
              <div class="zw-business-title">业务价值</div>
              <ul class="zw-business-context">
                <li v-for="(item,index) in businessList" :key="index">
                  <div class="zw-business-img">
                    <img :src="item.imgUrl" alt="">
                  </div>
                  <p>{{ item.title }}</p>
                  <span>{{ item.remarks }}</span>
                </li>
              </ul>
            </div>
            <!--应用场景-->
            <div class="zw-totalsolu-scene">
              <div class="zw-scene-title">应用场景</div>
              <div class="zw-scene-context">
                <div class="zw-scene-carousel">
                  <el-carousel :interval="-1" arrow="always" height="450px">
                    <el-carousel-item v-for="(item,index) in scenecarList" :key="index">
                      <div style="text-align: center">
                        <img :src="item.imgUrl" alt="">
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <ul class="zw-scene-list">
                  <li v-for="(item,index) in sceneList" :key="index">
                    <div class="zw-scene-img">
                      <img :src="item.imgUrl" alt="">
                    </div>
                    <p>{{ item.title }}</p>
                    <span>{{ item.remarks }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <!--客户案例-->
            <div class="zw-totalsolu-customer">
              <div class="zw-customer-title">客户案例</div>
              <ul class="zw-customer-list">
                <li v-for="(item,index) in customerList" :key="index">
                  <div>
                    <img :src="item.imgUrl" alt="">
                  </div>
                  <div class="zw-customer-right">
                    <p>{{ item.title }}</p>
                    <span>{{ item.content }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="业务价值" name="3">
          <!--业务价值-->
          <div class="zw-totalsolu-business">
            <div class="zw-business-title">业务价值</div>
            <ul class="zw-business-context">
              <li v-for="(item,index) in businessList" :key="index">
                <div class="zw-business-img">
                  <img :src="item.imgUrl" alt="">
                </div>
                <p>{{ item.title }}</p>
                <span>{{ item.remarks }}</span>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="应用场景" name="4">
          <!--应用场景-->
          <div class="zw-totalsolu-scene">
            <div class="zw-scene-title">应用场景</div>
            <div class="zw-scene-context">
              <div class="zw-scene-carousel">
                <el-carousel :interval="-1" arrow="always" height="450px">
                  <el-carousel-item v-for="(item,index) in scenecarList" :key="index">
                    <div style="text-align: center">
                      <img :src="item.imgUrl" alt="">
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
              <ul class="zw-scene-list">
                <li v-for="(item,index) in sceneList" :key="index">
                  <div class="zw-scene-img">
                    <img :src="item.imgUrl" alt="">
                  </div>
                  <p>{{ item.title }}</p>
                  <span>{{ item.remarks }}</span>
                </li>
              </ul>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="客户案例" name="5">
          <!--客户案例-->
          <div class="zw-totalsolu-customer">
            <div class="zw-customer-title">客户案例</div>
            <ul class="zw-customer-list">
              <li v-for="(item,index) in customerList" :key="index">
                <div>
                  <img :src="item.imgUrl" alt="">
                </div>
                <div class="zw-customer-right">
                  <p>{{ item.title }}</p>
                  <span>{{ item.content }}</span>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getShowAdvert } from '@/js/carousel'
import { pictureBaseUrl } from '@/utils/request'
export default {
  name: 'Solution',
  data() {
    return {
      pictureBaseUrl: pictureBaseUrl,
      picUrl: '',
      activeName: '1',
      customerList: [
        {
          imgUrl: require('../assets/img/khanfm.png'),
          title: '陕西财政：财政预算统一管理，让决策更智慧',
          content: '鑫众为助力陕西财政厅预算一体化运营中台，实现业务、财务、流程和数据一体化。对于资金集中管理，实时出具资金日报，通过票据智能识别、费控流程优化，减少员工报销耗时，提高工作效率，通过智能...'
        },
        {
          imgUrl: require('../assets/img/khanfm.png'),
          title: '陕西财政：财政预算统一管理，让决策更智慧',
          content: '鑫众为助力陕西财政厅预算一体化运营中台，实现业务、财务、流程和数据一体化。对于资金集中管理，实时出具资金日报，通过票据智能识别、费控流程优化，减少员工报销耗时，提高工作效率，通过智能...'
        },
        {
          imgUrl: require('../assets/img/khanfm.png'),
          title: '陕西财政：财政预算统一管理，让决策更智慧',
          content: '鑫众为助力陕西财政厅预算一体化运营中台，实现业务、财务、流程和数据一体化。对于资金集中管理，实时出具资金日报，通过票据智能识别、费控流程优化，减少员工报销耗时，提高工作效率，通过智能...'
        }
      ],
      sceneList: [
        {
          imgUrl: require('../assets/img/scene1.png'),
          title: '自动生成凭证',
          remarks: '业务数据通过会计平台自动生成财务核算凭证，减少财务人员大量繁琐的核算工作，让财务人员更聚焦管理职能，利用数据做出有效决策。'
        },
        {
          imgUrl: require('../assets/img/scene2.png'),
          title: '应收应付核销清晰可见',
          remarks: '应收应付单据是否收款、付款，收到多少、付款多少全过程核销情况清晰可见，与客户、供应商对账更轻松。'
        },
        {
          imgUrl: require('../assets/img/scene3.png'),
          title: '一站式企业费控',
          remarks: 'YonSuite费控电脑端、手机端都可使用，自动识别查重票据，报销自动生成凭证，让报销更简单。'
        },
        {
          imgUrl: require('../assets/img/scene4.png'),
          title: '一键合并',
          remarks: '内置灵活的抵消调整规则，将复杂的抵消业务逻辑转换成系统语言，一键合并，提高企业合并报表出具效率，让合并不再困扰财务人员。'
        }
      ],
      scenecarList: [
        {
          imgUrl: require('../assets/img/yycjbj.png')
        }
      ],
      businessList: [
        {
          imgUrl: require('../assets/img/ywjz1.png'),
          title: '云端部署 开箱即用',
          remarks: '实时、智能、自动的对企业经营活动数据进行采集、核算、分析'
        },
        {
          imgUrl: require('../assets/img/ywjz2.png'),
          title: '全球化',
          remarks: '支持多组织、集团管控、多会计准则、多语言、多币种核算'
        },
        {
          imgUrl: require('../assets/img/ywjz3.png'),
          title: '灵活费用报销',
          remarks: '支持费用预算、费用申请、借款、还款、费用报销以及多币种报销'
        },
        {
          imgUrl: require('../assets/img/ywjz4.png'),
          title: '业财大数据分析',
          remarks: '通过业务事项统一经营活动业务模型，基于事项分录归集业财大数据'
        }
      ]
    }
  },
  created() {
    this.getShowAdvert()
  },
  methods: {
    getShowAdvert() {
      getShowAdvert({ type: '3' }).then(res => {
        if (res && res.code === 200) {
          this.picUrl = res.data[0].url
        }
      })
    }
  }
}
</script>

<style>

</style>
